import {Temporal} from '@js-temporal/polyfill';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootStateT} from '../store';
import type {MessageT, RawMessageT} from '../../api/messageTypes';

export const WSStatus = {
  connected: 'connected',
  disconnected: 'disconnected',
  reconnecting: 'reconnecting',
  connecting: 'connecting',
  error: 'error',
};

export type WSStatusT = keyof(typeof WSStatus);

interface WSStateT {
  tick: number,
  status: WSStatusT,
  messages: MessageT[],
}


const initialState: WSStateT = {
  tick: 0,
  status: 'disconnected',
  messages: [],
};


export const WSSlice = createSlice({
  name: 'ws',
  initialState,
  reducers: {
    start_connecting: (state: WSStateT) => {
      state.status = 'connecting';
    },
    connect: (state: WSStateT) => {
      state.status = 'connected';
    },
    disconnect: (state: WSStateT) => {
      state.status = 'disconnected';
    },
    reconnect: (state: WSStateT) => {
      state.status = 'reconnecting';
      state.tick = 0;
    },
    retriesLimit: (state: WSStateT) => {
      state.status = 'error';
    },
    tick: (state: WSStateT) => {
      state.tick++;
    },
    message: (state: WSStateT, action: PayloadAction<RawMessageT>) => {
      state.messages.push({
        ...action.payload as RawMessageT,
        time: Temporal.Now.plainDateTimeISO(),
      });
    },
  },
});

export const WSActions = WSSlice.actions;
export const selectTicks = (state: RootStateT) => state.WSReducer.tick;
export const selectStatus = (state: RootStateT) => state.WSReducer.status;
export const selectMessages = (state: RootStateT) => state.WSReducer.messages;

export default WSSlice.reducer;
