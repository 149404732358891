/* eslint-disable */
import React, {
  FC,
  FormEvent,
  FormEventHandler,
  useState,
  useRef,
  useEffect,
} from 'react';
import {Link} from 'react-router-dom';
import ValidatableInput from '../../components/validatableInput/validatableInputComponent';
import Сarousel from '../../components/carousel/carouselComponent';

import regexpValidation, {itnSymbols} from '../../utils/regexp';

import './register.scss';
import Ajax from '../../api/ajax';
import {PartySuggestions} from '../../libs/components/dadata/PartySuggestions';
// import {DadataToken} from '../../api/tokens';
import {
  BaseState,
  DaDataParty,
  DaDataSuggestion,
} from '../../libs/components/dadata/types';
import useMaterialize from '../../hooks/useMaterialize';
import {HttpCache} from '../../libs/components/dadata/http-cache/abstract';
import {ajaxDebug} from '../../utils/globals';
import {Box, useMediaQuery} from '@mui/material';

export interface Company {
  legalName: string;
  brandName: string;
  address: string;
  opf: string;
}



interface RegisterFormPropsI {
  handleSubmit: FormEventHandler<HTMLFormElement>;

  manageEmailState: [string, (s: string) => void];
  managePasswordState: [string, (s: string) => void];
  manageRepeatPasswordState: [string, (s: string) => void];

  manageSurnameState: [string, (s: string) => void];
  manageFirstnameState: [string, (s: string) => void];
  managePatronymicState: [string, (s: string) => void];
  manageCountryState: [string, (s: string) => void];
  managePositionState: [string, (s: string) => void];

  manageITNState: [string, (s: string) => void];
  manageCompanyState: [Company, (с: Company) => void];

  manageUniqueEmail: [boolean, (s: boolean) => void];

  srvErr: string;
  isRememberMeChecked: boolean;
  setIsRememberMeChecked: (newIsRememberMeChecked: boolean) => void;
}

const INVALID_FIELDS_DEFAULT_SIGN = 'Нарушен формат полей';

const RegisterFormComponent: FC<RegisterFormPropsI> = (props) => {
  const [uniqEmailChech, setUniqEmailChech] = useState(false)
  const {
    manageEmailState,
    managePasswordState,
    manageRepeatPasswordState,
    manageSurnameState,
    manageFirstnameState,
    managePatronymicState,
    managePositionState,
    manageITNState,
    manageCompanyState,
    manageUniqueEmail,
    srvErr,
    isRememberMeChecked,
    setIsRememberMeChecked,
  } = props;
  const [isEmailUnique, setIsEmailUnique] = useState<{[key: string]: boolean}>(
    {},
  );

  

  // TODO: когда 400 при ререндере шлется еще один запрос
  const checkEmailUnique = () =>
    // console.log(test)
    Ajax.post({
      url: '/user/check',
      body: {
        email: manageEmailState[0],
      },
    }).then(({status, response}) => {
      if (status === Ajax.STATUS.notFound) {
        // no such email
        setIsEmailUnique({
          ...isEmailUnique,
          [manageEmailState[0]]: true,
        });
        setUniqEmailChech(true);
        // TODO: снять лоадер
        stageOneNextButtonRef.current?.click();
      } else if (status === Ajax.STATUS.ok) {
        manageUniqueEmail[0] = true;
        setIsEmailUnique({
          ...isEmailUnique,
          [manageEmailState[0]]: false,
        });
        
      } else {
        console.warn(response.msg);
      }
      // console.log('checkEmailUnique', isEmailUnique)
    });
    
  // stage 1
  const stageOneNextButtonRef = useRef<HTMLButtonElement>(null);

  const [stageOnePreviousError, setStageOnePreviousError] = useState('');
  const [showStageOneError, setShowStageOneError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const stageOneValid =
    uniqEmailChech &&
    isEmailValid &&
    isPasswordValid &&
    !!manageEmailState[0] &&
    !!managePasswordState[0] &&
    !!manageRepeatPasswordState[0] &&
    managePasswordState[0] === manageRepeatPasswordState[0];

  // console.log({
  //   isEmailValid,
  //   isPasswordValid,
  //   '!!manageEmailState[0]': !!manageEmailState[0],
  //   '!!managePasswordState[0]': !!managePasswordState[0],
  //   '!!manageRepeatPasswordState[0]': !!manageRepeatPasswordState[0],
  //   'managePasswordState[0] === manageRepeatPasswordState[0]':
  //    managePasswordState[0] === manageRepeatPasswordState[0],
  //   'isEmailUnique[manageEmailState[0]]': isEmailUnique[manageEmailState[0]],
  //   isEmailUnique,
  // });

  let stageOneInvalidReason = '';
  if (!stageOneValid) {
    if (manageEmailState[0] === '') {
      stageOneInvalidReason = 'Пропущено поле E-mail';
    } else if (!manageUniqueEmail) {
      stageOneInvalidReason = 'Пользователь с таким E-mail уже существует';
    } else if (managePasswordState[0] === '') {
      stageOneInvalidReason = 'Пропущено поле Пароль';
    } else if (manageRepeatPasswordState[0] === '') {
      stageOneInvalidReason = 'Пропущено поле Повторите пароль';
    } else if (
      managePasswordState[0] !== '' &&
      managePasswordState[0] !== manageRepeatPasswordState[0]
    ) {
      stageOneInvalidReason = 'Пароли не совпадают';
    } else {
      stageOneInvalidReason = INVALID_FIELDS_DEFAULT_SIGN;
    }
  }

  const stageOneSubmit = () => {
    if (!uniqEmailChech) {
      checkEmailUnique().then(() => {
        console.log('stageOneSubmit: \n',"stageOneValid = ", stageOneValid,"\n uniqueEmail = ", uniqEmailChech);
      });
      if (stageOneValid) {
        return;
      }
      if (!stageOneValid) {
        setShowStageOneError(true);
        setStageOnePreviousError(stageOneInvalidReason);
        return;
      }
    }else{
      console.log('Valid stageOneSubmit: \n',"stageOneValid = ", stageOneValid,"\n uniqueEmail = ", uniqEmailChech);
      stageOneNextButtonRef.current?.click();
      return;
    }



    // if (!(manageEmailState[0] in isEmailUnique)) {
      // TODO: лоадер
    // }
  };
  // предыдущая ошибка исправлена
  if (showStageOneError && stageOneInvalidReason !== stageOnePreviousError) {
    setShowStageOneError(false);
  }

  // stage 2
  const [StageTwoPreviousError, setStageTwoPreviousError] = useState('');
  const [showStageTwoError, setShowStageTwoError] = useState(false);
  const [isFirstnameValid, setIsFirstnameValid] = useState(true);
  const [isSurnameValid, setIsSurnameValid] = useState(true);
  const [isPatronymicValid, setIsPatronymicValid] = useState(true);
  const [isPositionValid, setIsPositionValid] = useState(true);

  const stageTwoValid =
    isSurnameValid &&
    isFirstnameValid &&
    isPatronymicValid &&
    isPatronymicValid &&
    isPositionValid &&
    !!manageSurnameState[0] &&
    !!manageFirstnameState[0] &&
    !!managePatronymicState[0] &&
    !!managePositionState[0];

  let stageTwoInvalidReason = '';
  if (!stageTwoValid) {
    if (manageSurnameState[0] === '') {
      stageTwoInvalidReason = 'Пропущено поле Фамилия';
    } else if (manageFirstnameState[0] === '') {
      stageTwoInvalidReason = 'Пропущено поле Имя';
    } else if (managePatronymicState[0] === '') {
      stageTwoInvalidReason = 'Пропущено поле Отчество';
    } else if (managePositionState[0] === '') {
      stageTwoInvalidReason = 'Пропущено поле Должность';
    } else {
      stageTwoInvalidReason = INVALID_FIELDS_DEFAULT_SIGN;
    }
  }

  const stageTwoSubmit = () => {
    if (stageTwoValid) {
      return;
    }
    setShowStageTwoError(true);
    setStageTwoPreviousError(stageTwoInvalidReason);
  };
  if (showStageTwoError && stageTwoInvalidReason !== StageTwoPreviousError) {
    setShowStageTwoError(false);
  }

  // stage 3
  const [stageThreePreviousError, setStageThreePreviousError] = useState('');
  const [showStageThreeError, setShowStageThreeError] = useState(false);
  // const [isItnValid, setIsItnValid] = useState(true);
  const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
  const [isCompanyLegalNameValid, setIsCompanyLegalNameValid] = useState(true);

  const [dadataState, setDadataState] = useState<BaseState<DaDataParty>>({
    query: '',
    inputQuery: '',
    isFocused: false,
    displaySuggestions: true,
    suggestions: [],
    suggestionIndex: -1,
  });

  const isItnValid =
    (dadataState.query.length === 10 || dadataState.query.length === 12) &&
    itnSymbols.test(dadataState.query);

  const selectedSuggestion = dadataState.suggestions.filter(
    ({data: {inn}}) => inn === dadataState.query,
  )[0];
  // const selectedSuggestion = dadataState.suggestions[dadataState.suggestionIndex];

  useEffect(() => {
    if (
      isItnValid &&
      dadataState.query !== manageITNState[0] &&
      dadataState.suggestions.length !== 0
    ) {
      if (selectedSuggestion) {
        manageCompanyState[1]({
          legalName: selectedSuggestion.value,
          brandName: selectedSuggestion.data.name.short,
          address: selectedSuggestion.data.address.value,
          opf: selectedSuggestion.data.opf.short,
        });
      } else {
        manageCompanyState[1]({
          legalName: '',
          brandName: '',
          address: '',
          opf: '',
        });
      }

      manageITNState[1](dadataState.query);
    }
  }, [
    selectedSuggestion,
    dadataState,
    manageCompanyState,
    isItnValid,
    manageITNState,
  ]);

  const M = useMaterialize();
  const selectRef = useRef<HTMLSelectElement>(null);
  useEffect(() => {
    M.FormSelect.init(selectRef.current);
  }, [M]);

  const genManageCompanyFieldState = (
    field: keyof (typeof manageCompanyState)[0],
  ) =>
    [
      manageCompanyState[0][field],
      (value: typeof field) =>
        manageCompanyState[1]({...manageCompanyState[0], [field]: value}),
    ] as [string, (s: string) => void];

  const manageCompanyBrandNameState = genManageCompanyFieldState('brandName');
  const manageCompanyLegalNameState = genManageCompanyFieldState('legalName');

  const stageThreeValid = true;
    // isCompanyNameValid &&
    // isCompanyLegalNameValid &&
    // isItnValid &&
    // !!dadataState.query &&
    // !!manageCompanyState[0].legalName &&
    // !!manageCompanyState[0].brandName &&
    // !!manageCompanyState[0].opf;

   let stageThreeInvalidReason = '';

  //TODO: убрал валидлацию ИНН 

  // if (!stageThreeValid) {
  //   if (dadataState.query === '') {
  //     stageThreeInvalidReason = 'Пропущено поле ИНН';
  //   } else if (!isItnValid) {
  //     stageThreeInvalidReason =
  //       'ИНН - 10 цифр для юр.лица или 12 - для физ.лица';
  //   } else if (manageCompanyState[0].legalName === '') {
  //     stageThreeInvalidReason = 'Пропущено поле Полное Название';
  //   } else if (manageCompanyState[0].brandName === '') {
  //     stageThreeInvalidReason = 'Пропущено поле Название';
  //   } else if (manageCompanyState[0].opf === '') {
  //     stageThreeInvalidReason = 'Пропущено поле Организационно-правовая форма';
  //   } else {
  //     stageThreeInvalidReason = INVALID_FIELDS_DEFAULT_SIGN;
  //   }
  // }

  if (
    showStageThreeError &&
    stageThreeInvalidReason !== stageThreePreviousError
  ) {
    setShowStageThreeError(false);
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (stageOneValid && stageTwoValid && stageThreeValid) {
      props.handleSubmit(e);
      return;
    }
    if (!stageOneValid || !stageTwoValid) {
      // нужный класс для скролла на невалидную часть формы уже есть в виде класса на кнопке сабмита
      return;
    }

    setShowStageThreeError(true);
    setStageThreePreviousError(stageThreeInvalidReason);
  };

  if (srvErr) {
    // TODO: мб скроить на поселнюю страницу
  }

  const matches = useMediaQuery('(max-width: 768px)');
  // ломается, если хотя бы 1 поле дохнет на ПЕРВОЙ странице карусели!
  // по причине html5 валидации
  return (
    <form onSubmit={handleSubmit}>
      <h4>Быстрая регистрация</h4>
      <div className="row"></div>

      <Сarousel
        className="register"
        dist={0}
        padding={100}
        fullWidth={false}
        indicators={true}
        duration={100}
        isSlider={true}
        disableMouseDrag={true}
      >
        {/* stage 1 */}
        <div className="register__stage">
          <div className="row register__stage__fields">
            <Box className="row">
              <ValidatableInput
                className="col s12"
                id="E-mail"
                labelSign="E-mail"
                type="text" // 'email' валидируется через regexpValidation. Иначе форма визуально багается
                trimmed={true}
                valueState={manageEmailState}
                required={true}
                tooltipWidth="200px"
                conditions={regexpValidation.email.conditions}
                validState={[isEmailValid, setIsEmailValid]}
              />
            </Box>
            <div className="row">
              <ValidatableInput
                className="col s12"
                id="password"
                type="password"
                labelSign="Пароль"
                trimmed={false}
                valueState={managePasswordState}
                required={true}
                tooltipWidth="200px"
                conditions={regexpValidation.password.conditions}
                validState={[isPasswordValid, setIsPasswordValid]}
              />
            </div>
            <div className="row">
              <ValidatableInput
                className="col s12"
                id="password-repeat"
                type="password"
                labelSign="Повторите пароль"
                trimmed={false}
                valueState={manageRepeatPasswordState}
                required={true}
                tooltipWidth="200px"
                conditions={regexpValidation.password.conditions}
              />
            </div>
          </div>

          <div className="row">
            <p className="auth__error-message">
              {showStageOneError ? stageOneInvalidReason : ''}
            </p>
          </div>

          <div className="row">
            <p className="">
              {isEmailUnique[manageEmailState[0]] === false
                ? 'Пользователь с таким Email уже существует'
                : ''}
            </p>
          </div>

          {/* stage 1 maintainance buttons */}
          <div className="row register__stage__buttons">
            <div className="col right">
              <button
                className={
                  'btn theme-blue waves-effect waves-light' +
                  (stageOneValid && uniqEmailChech ===true
                    ? ' next'
                    : '')
                }
                ref={stageOneNextButtonRef}
                onClick={stageOneSubmit}
                type="button"
              >
                Далее
                <i
                  className={
                    'material-icons right'+ (stageOneValid && uniqEmailChech===true 
                      ? ' next' : '')
                  }
                >
                  arrow_forward
                </i>
              </button>
            </div>
          </div>
        </div>
        {/* end stage 1 */}

        {/* stage 2 */}
        <div className="register__stage">
          <div className="row register__stage__fields">
            <div className="row">
              <ValidatableInput
                className="col s12"
                id="Surname"
                labelSign="Фамилия"
                trimmed={true}
                required={true}
                tooltipWidth="200px"
                conditions={regexpValidation.lastName.conditions}
                validState={[isSurnameValid, setIsSurnameValid]}
                valueState={manageSurnameState}
              />
            </div>
            <div className="row">
              <ValidatableInput
                className="col s12"
                id="Firstname"
                labelSign="Имя"
                required={true}
                trimmed={true}
                tooltipWidth="200px"
                conditions={regexpValidation.firstName.conditions}
                validState={[isFirstnameValid, setIsFirstnameValid]}
                valueState={manageFirstnameState}
              />
            </div>
            <div className="row">
              <ValidatableInput
                className="col s12"
                id="Patronymic"
                labelSign="Отчество"
                required={true}
                trimmed={true}
                tooltipWidth="200px"
                conditions={regexpValidation.firstName.conditions}
                validState={[isPatronymicValid, setIsPatronymicValid]}
                valueState={managePatronymicState}
              />
            </div>
            {/* TODO: удалить пробелы */}
            <div className="row">
              <ValidatableInput
                className="col s12"
                id="Position"
                labelSign="Должность"
                trimmed={false}
                required={true}
                tooltipWidth="200px"
                conditions={regexpValidation.post.conditions}
                validState={[isPositionValid, setIsPositionValid]}
                valueState={managePositionState}
              />
            </div>
          </div>

          <div className="row">
            <p className="auth__error-message">
              {showStageTwoError ? stageTwoInvalidReason : ''}
            </p>
          </div>

          {/* stage 2 maintainance buttons */}
          <div className="row register__stage__buttons">
            <div className="col">
              <button
                className="btn-flat theme-border theme-white waves-effect waves-light prev"
                type="button"
              >
                Назад
              </button>
            </div>
            <div className="col right">
              <button
                className={
                  'btn theme-blue waves-effect waves-light' +
                  (stageTwoValid ? ' next' : '')
                }
                onClick={stageTwoSubmit}
                type="button"
              >
                Далее
                <i
                  className={
                    'material-icons right' + (stageTwoValid ? ' next' : '')
                  }
                >
                  arrow_forward
                </i>
              </button>
            </div>
          </div>
        </div>
        {/* end stage 2 */}

        {/* stage 3 */}
        <div className="register__stage">
          <div className="row register__stage__fields">
            {/* TODO: заменить */}
            {/* validState={[isItnValid, setIsItnValid]} */}
            <PartySuggestions
              httpCache={true}
              substituteOnClick={(suggestion) => suggestion.data.inn}
              delay={200}
              inputProps={{placeholder: 'ИНН'}}
              manageState={[dadataState, setDadataState]}
              createRequestPromise={function <DaDataParty>(
                query: string,
                cache: HttpCache | null,
              ) {
                if (!query) {
                  if (ajaxDebug)
                    console.log(
                      '[Register Form => DaDataParty] не отправляю пустое поле',
                    );
                  return (async () => [])();
                }

                let cacheKey: string;
                if (cache) {
                  cacheKey = cache.serializeCacheKey({
                    url: query,
                    requestParams: {},
                  });
                  const cachedData = cache.get(
                    cacheKey,
                  ) as unknown as DaDataSuggestion<DaDataParty>[];
                  if (cachedData) {
                    if (ajaxDebug)
                      console.log(
                        '[Register Form => DaDataParty] из кеша:',
                        cachedData,
                      );
                    return (async () => cachedData)();
                  }
                }

                return Ajax.post({
                  url: `/company/itn/${query}`,
                  body: {},
                })
                  .then(({status, response}) => {
                    const retVal =
                      (status === Ajax.STATUS.ok &&
                        (response.data as DaDataSuggestion<DaDataParty>[])) ||
                      [];
                    cache?.set(cacheKey, retVal);
                    return retVal;
                  })
                  .catch((err) => {
                    console.warn(err);
                    return [];
                  });
              }}
            />
            <span
              className={'register__company' + (isItnValid ? ' active' : '')}
            >
              <div className="row" />
              <div className="row" />
              {selectedSuggestion ? (
                <p className="text-secondary">Проверьте, все ли верно</p>
              ) : (
                <p className="text-secondary">
                  Компания не найдена в нашем реестре.
                  <br />
                  Проверьте правильность ввода ИНН
                  <br />
                  или заполните данные вручную
                </p>
              )}
              <div className="row" />
              <div className="row">
                <ValidatableInput
                  className="col s12"
                  id="CompanyLegalName"
                  labelSign="Полное название"
                  required={true}
                  trimmed={false}
                  tooltipWidth="200px"
                  conditions={regexpValidation.companyFullName.conditions}
                  valueState={manageCompanyLegalNameState}
                  validState={[
                    isCompanyLegalNameValid,
                    setIsCompanyLegalNameValid,
                  ]}
                />
              </div>
              <div className="row">
                <ValidatableInput
                  className="col s12"
                  id="CompanyBrandName"
                  labelSign="Название бренда"
                  required={true}
                  trimmed={false}
                  tooltipWidth="200px"
                  conditions={regexpValidation.companyName.conditions}
                  valueState={manageCompanyBrandNameState}
                  validState={[isCompanyNameValid, setIsCompanyNameValid]}
                />
              </div>
            </span>
          </div>

          <div className="row">
            <p className="auth__error-message">
              {showStageThreeError ? stageThreeInvalidReason : ''}
            </p>
            {srvErr && <p className="auth__error-message">{srvErr}</p>}
          </div>

          {/* stage 3 maintainance buttons */}
          <div className="row register__stage__buttons">
            <div className="col">
              <button
                className="btn-flat theme-border theme-white waves-effect waves-light prev"
                type="button"
              >
                Назад
              </button>
            </div>
            <div className="col right">
              <button
                className={
                  'btn theme-blue waves-effect waves-light' +
                  (!stageOneValid ? ' set-0' : !stageTwoValid ? ' set-1' : '')
                }
                type="submit"
              >
                Регистрация
                <i
                  className={
                    'material-icons right' +
                    (!stageOneValid ? ' set-0' : !stageTwoValid ? ' set-1' : '')
                  }
                >
                  arrow_forward
                </i>
              </button>
            </div>
          </div>
        </div>
        {/* end stage 3 */}
      </Сarousel>

      {srvErr ? (
        <div className="row">
          <p>{srvErr}</p>
        </div>
      ) : (
        ''
      )}

      <div className="row">
        <label className="col" style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
          <input
            type="checkbox"
            className="filled-in theme"
            defaultChecked={isRememberMeChecked}
            onChange={(prev) => setIsRememberMeChecked(!prev)}
          />
          <h6>Запомнить меня</h6>
        </label>
        <div className="col valign-wrapper right" style={{height: '25px'}}>
          <Link to="#">Забыли пароль?</Link>
        </div>
      </div>

      <div className="row"></div>

      <div className="row">
        <div className="col right" style={{padding: '0'}}>
          <Link to={'/login'}>
            <button
              className="btn-flat theme-border theme-white waves-effect waves-light"
              type="button"
            >
              Уже есть аккаунт
            </button>
          </Link>
        </div>
      </div>
    </form>
  );
};

export default RegisterFormComponent;
