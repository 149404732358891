import React from 'react';
import {Stack, Typography} from '@mui/material';
import CardsAdvantages from '../CardsAdvantages';

export default function TextBlock() {
  return (
    <Stack
      component="section"
      sx={{
        maxWidth: '1314px',
        width: '100%',
        heigh: '592px',
        margin: '48px 64px',
        borderRadius: '24px',
        display: 'flex',
        flexDirection: 'columnt',
      }}
    >
      <Typography sx={{fontSize: '24px', color: '#707895'}}>
        Наша миссия:{' '}
      </Typography>
      <Typography sx={{fontSize: '48px', color: '#272121', fontWeight: '600', width: '100%', maxWidth: '1200px'}}>
        Сделать онлайн-торговлю в B2B быстрой, удобной, выгодной и прозрачной
      </Typography>
      <CardsAdvantages />
    </Stack>
  );
}
