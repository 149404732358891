import React, {FC} from 'react';

import './landings.scss';

import FSILogo from '../../static/imgs/landings/fastRegister/fsi logo.png';
import SberLogo from '../../static/imgs/landings/fastRegister/sber logo.png';
import PMEFLogo from '../../static/imgs/landings/fastRegister/pmef logo.png';
import codesandboxIcon from '../../static/imgs/landings/fastRegister/codesandbox.svg';
import usersIcon from '../../static/imgs/landings/fastRegister/users.svg';
import creditCardIcon from '../../static/imgs/landings/fastRegister/credit-card.svg';
import globeIcon from '../../static/imgs/landings/fastRegister/globe.svg';

const FastRegisterLanding: FC = () => (
  <div className='fast-register-landing p-[0]'>
    <div className="fastRegistrImg bg-no-repeat"></div>
  </div>
  // <div className='fast-register-landing white-text'>
  //   <div className='l-container'>
  //     {/* ПМЭФ */}
  //     <div className='l-row w-100'>
  //       <div className='l-table w-100'>
  //         <div className='flex-grow-1 l-center w-100 white plate-flat' >
  //           <img src={PMEFLogo} alt='логотип ПМЭФ'/>
  //         </div>
  //         <div className='flex-grow-1 l-center w-100 mh-20 white plate-flat' >
  //           <img src={SberLogo} alt='логотип Сбер'/>
  //         </div>
  //         <div className='flex-grow-1 l-center w-100 white plate-flat' >
  //           <img src={FSILogo} alt='логотип ФСИ'className='d-flex'/>
  //         </div>
  //       </div>
  //     </div>
  //     <div className='l-row w-100'>
  //       <div className='l-table w-100 fast-register-landing__accelarators_sign'>
  //         <p className='d-flex flex-grow-1 mh-10 center w-100'>Победители проекта-участника ПМЭФ 2022</p>
  //         <p className='d-flex flex-grow-1 mh-20 center w-100'>Участники акселератора SberStudent</p>
  //         <p className='d-flex flex-grow-1 mh-10 center w-100'>Проект выиграл грант от ФСИ на сумму 1 млн. руб.</p>
  //       </div>
  //     </div>

  //     {/* Ваш бизнес — прибыльнее */}
  //     <div className='l-row w-100'>
  //       <h2>
  //         Ваш бизнес — прибыльнее
  //       </h2>
  //     </div>
  //     {/* 4 опции */}
  //     <div className='l-row w-100'>
  //       <div className='l-table jc-sp-btw w-100'>
  //         <div className='l-row flex-grow-1 w-100 m-10'>
  //           <div className='fast-register-landing__options__icon-column'>
  //             <h5>
  //               <div className='fast-register-landing__options__icon_frame theme-white'>
  //                 <img src={codesandboxIcon} alt='иконка' className='fast-register-landing__options__icon'/>
  //               </div>
  //             </h5>
  //           </div>
  //           <div className='fast-register-landing__options__text-column'>
  //             <h5 className='fast-register-landing__options__title'>Помогаем находить лучших поставщиков</h5>
  //             <p className='fast-register-landing__options__text'>Умный поиск, система агрегации
  //               и сравнения предложений, отзывы и рейтинги
  //             </p>
  //           </div>
  //         </div>
  //         <div className='l-row flex-grow-1 w-100 m-10'>
  //           <div className='fast-register-landing__options__icon-column'>
  //             <h5>
  //               <div className='fast-register-landing__options__icon_frame theme-white'>
  //                 <img src={creditCardIcon} alt='иконка' className='fast-register-landing__options__icon'/>
  //               </div>
  //             </h5>
  //           </div>
  //           <div className='fast-register-landing__options__text-column'>
  //             <h5 className='fast-register-landing__options__title'>Экономим время и деньги</h5>
  //             <p className='fast-register-landing__options__text'>Торговля без НДС, ускорение и автоматизация сделок,
  //               электронные документооборот и подпись, интегрированный биллинг
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className='l-row w-100'>
  //       <div className='l-table jc-sp-btw w-100'>
  //         <div className='l-row flex-grow-1 w-100 m-10'>
  //           <div className='fast-register-landing__options__icon-column'>
  //             <h5>
  //               <div className='fast-register-landing__options__icon_frame theme-white'>
  //                 <img src={usersIcon} alt='иконка' className='fast-register-landing__options__icon'/>
  //               </div>
  //             </h5>
  //           </div>
  //           <div className='fast-register-landing__options__text-column'>
  //             <h5 className='fast-register-landing__options__title'>Расширяем базу клиентов</h5>
  //             <p className='fast-register-landing__options__text'>Инструменты маркетинга и презентации, рекомендации,
  //               интеграция внешних CRM, автоматическая система скидок, чат и чат-боты
  //             </p>
  //           </div>
  //         </div>
  //         <div className='l-row flex-grow-1 w-100 m-10'>
  //           <div className='fast-register-landing__options__icon-column'>
  //             <h5>
  //               <div className='fast-register-landing__options__icon_frame theme-white'>
  //                 <img src={globeIcon} alt='иконка' className='fast-register-landing__options__icon'/>
  //               </div>
  //             </h5>
  //           </div>
  //           <div className='fast-register-landing__options__text-column'>
  //             <h5 className='fast-register-landing__options__title'>Быстрый старт бизнеса в онлайн</h5>
  //             <p className='fast-register-landing__options__text'>Простой и понятный интерфейс
  //               с персонализацией, настройка уровней доступа и подпись, интегрированный биллинг
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>

  //     {/* Запуск продукта — 4 квартал 2022 */}
  //     <div className='l-row w-100 section'>
  //       <h4 className='m-0 plate-flat theme-blue-accent'>Запуск продукта — 4 квартал 2022</h4>
  //     </div>
  //   </div>
  // </div>

);

export default FastRegisterLanding;

