import type {InputHTML5ValidationAttributesT} from '../../utils/regexp';

export const fastOrderValidation = {
  productCategory: {
    pattern: /^.{3,128}$/,
    errorMsg: 'Строка от 3 до 128 символов',
    validation: {
      minLength: 3,
      maxLength: 128, // самая длинная в базе - 85
    },
    conditions: [{
      condition: (value) => value.length > 128 || value.length < 3,
      msgOnTrue: 'Длина от 3 до 128 символов',
    }],
  } as InputHTML5ValidationAttributesT,

  productName: {
    pattern: /^.{3,256}$/,
    errorMsg: 'Строка от 3 до 256 символов',
    validation: {
      minLength: 3,
      maxLength: 256,
    },
    conditions: [{
      condition: (value) => value.length > 256 || value.length < 3,
      msgOnTrue: 'Длина от 3 до 256 символов',
    }],
  } as InputHTML5ValidationAttributesT,

  orderText: {
    pattern: /^.{3,1000}$/,
    errorMsg: 'Строка от 3 до 1000 символов',
    validation: {
      minLength: 3,
      maxLength: 128,
    },
    conditions: [{
      condition: (value) => value.length > 1000 || value.length < 3,
      msgOnTrue: 'Длина от 3 до 1000 символов',
    }],
  } as InputHTML5ValidationAttributesT,

  // orderComments - то же самое, что orderText
} as const;
