/* eslint-disable */
import React, {FC, useEffect, useState} from 'react';
import RootPage from './rootPage';
import clickboard from '../static/companyPage/clickboard.svg';
import mail from '../static/companyPage/mail.svg';
import phone from '../static/companyPage/phone.svg';
import Ajax from '../api/ajax';
import {GoodT, changeCurrGoods} from '../redux/reducers/categoriesReducer';
import store from '../redux/store';
import {useAppSelector} from '../redux/hooks';
import {useLocation} from 'react-router';
import Card from '../components/card/card';
import Button from '@mui/material/Button/Button';
import axios from 'axios';
import {APIurl} from '../utils/globals';
const requestProductsLimit = 12;
const requestSearchProductsLimit = 12;
type CompanyT = {
  id: number;
  name: string;
  description: string;
  legal_name: string;
  itn: string;
  psrn: string;
  address: string;
  legal_address: string;
  email: string;
  phone: string;
  link: string;
  activity: string;
  owner_id: number;
  rating: number;
  verified: number;
};
const CompanyPage: React.FC = () => {
  const [itemsLayout, setItemsLayout] = useState('table-layout');
  const [fetching, setFetching] = useState(true);
  const [itemsAmount, setItemsAmount] = useState(0);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [companyInfo, setCompanyInfo] = useState<CompanyT>({
    id: 0,
    name: '',
    description: '',
    legal_name: '',
    itn: '',
    psrn: '',
    address: '',
    legal_address: '',
    email: '',
    phone: '',
    link: '',
    activity: '',
    owner_id: 0,
    rating: 0,
    verified: 0,
  });
  const location = useLocation().pathname;
  const getId = (location: string) => {
    return Number(
      location.substring(location.length, location.lastIndexOf('/') + 1),
    );
  };
  // let {
  //   category: currCategory,
  //   subCatArr,
  //   goods,
  // } = useAppSelector((state) => state.categoryReducer);
  let goods = useAppSelector((state) => state.categoryReducer.goods);
  useEffect(() => {
    const fetchCompanyProducts = async () => {
      try {
        const response = await fetch(
          `${APIurl}/company/products?skip=${itemsAmount}&limit=${requestProductsLimit}`,
          {
            method: 'POST',
            body: JSON.stringify({company_id: getId(location)}),
          },
        );
        if (response.ok) {
          const companyProducts = await response.json();
          if (Array.isArray(companyProducts.data)) {
            store.dispatch(changeCurrGoods(companyProducts.data));
            setItemsAmount(companyProducts.data.length);
          }
        }
      } catch (e) {
        console.error('Error fetching products:', e);
      } finally {
        setFetching(false);
      }
    };
    const timeout = setTimeout(() => {
      fetchCompanyProducts();
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [location]);
  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await axios.get(
          `${APIurl}/company/${getId(location)}`,
        );
        if (response.status === 200) {
          console.log(response.data.data);
          setCompanyInfo(response.data.data as CompanyT);
        } else {
          console.error('Error:', response.status);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchCompany();
  }, [location]);
  const renderedProducts = Array.isArray(goods)
    ? goods.map(
        (
          {id, name, price, photo, description, category_id, category_name},
          i,
        ) => {
          return (
            <Card
              key={'' + i}
              id={id}
              category_id={category_id}
              category_name={category_name}
              name={name}
              price={price}
              photo={photo ? photo[0] : ''}
              description={'Описание'}
              classList={'card ' + itemsLayout}
              currentUser={currentUser}
            />
          );
        },
      )
    : 'Ничего не найдено';

  return (
    <RootPage nameOfActive="Company">
      {companyInfo && (
        <main className="flex py-12 px-16 gap-8">
          <aside className="flex flex-col w-[420px]">
            <div className="rounded-full bg-[#dfe3e7] w-36 h-36 mb-8" />
            <h4 className="text-[#5075f9] text-[24px] font-semibold">
              {companyInfo.name}
            </h4>
            <section className="flex flex-col gap-2 mb-6">
              <InfoBlock
                icon={clickboard}
                text={`ИНН организации: ${companyInfo.itn}`}
              />
              <InfoBlock icon={mail} text={`Email: ${companyInfo.email}`} />
              <InfoBlock icon={phone} text={`Телефон: ${companyInfo.phone}`} />
            </section>
            <h5 className="text-[16px] font-semibold text-[#5075f9]">
              О компании
            </h5>
            <h6 className="text-[14px] text-[#272121] leading-[150%] mb-10">
              {companyInfo.description}
            </h6>
            <Button className="!bg-[#5075f9] !text-white">Написать</Button>
          </aside>
          <section className="flex flex-wrap gap-8">{renderedProducts}</section>
        </main>
      )}
    </RootPage>
  );
};

const InfoBlock: FC<{icon: any; text: string}> = (props) => {
  return (
    <div className="flex gap-2 items-center rounded-[6px] p-3 w-[420px] h-[40px] bg-[#e6eaf7]">
      <img src={props.icon} alt="icon" />
      <h6 className="text-[#272121]  font-medium text-[14px] mb-3">
        {props.text}
      </h6>
    </div>
  );
};
export default CompanyPage;
