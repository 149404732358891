/*eslint-disable */
import React from 'react';
import {Modal, Box, IconButton, Typography, Button} from '@mui/material';
import IModal from '../../../pages/landing/types/IModal';
import s from './howditwork.module.scss';
import logo from '../../../static/landing/logoo.svg';
import closeIcon from '../../../static/landing/addcircle.svg';
import {useNavigate} from 'react-router';
import { Link } from 'react-router-dom';

const ModalHowDItWork: React.FC<IModal> = ({
  open,
  handleClose,
  handleIconClose,
  isMobile,
}) => {
  const navigate = useNavigate();
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        className={s.wrapper}
        sx={
          isMobile
            ? {paddingX: '10px', maxWidth: '100vw', paddingY: '20px'}
            : {
                paddingX: '40px',
                paddingTop: '43px',
                paddingBottom: '30px',
                width: '560px',
              }
        }
      >
        <IconButton
          sx={{
            right: 0,
            top: 0,
            marginRight: '5px',
            marginBottom: '3px',
            position: 'absolute',
          }}
          onClick={() => handleClose(false)}
        >
          <img src={closeIcon} alt="" />
        </IconButton>
        <img src={logo} alt="logo" />
        <Typography
          className={s.hdiw}
          style={{
            fontSize: '28px',
            fontWeight: '600',
            marginTop: '44px',
            marginBottom: '24px',
          }}
        >
          Как это работает?
        </Typography>
        <Box component="main" className={s.main}>
          <Box className={s.block}>
            <Typography>1. Оcтавьте данные для связи</Typography>
            <Typography style={{color: '#707895'}}>
              Оcтавьте данные для связи – наш алгоритмы проанализирует весь
              рынок и подберёт наиболее релевантных поставщиков – поставщики
              формируют свои уникальные КП под ваш запрос.
            </Typography>
          </Box>
          <Box className={s.block}>
            <Typography>2. Формирование КП</Typography>
            <Typography>
              Поставщики формируют свои уникальные КП под ваш запрос.
            </Typography>
          </Box>
          <Box className={s.block}>
            <Typography>3. Результат</Typography>
            <Typography>
              Вы получите список коммерческих предложений от поставщиков на
              почту.
            </Typography>
          </Box>
          <Box className={s.block}>
            <Typography><Link to={'https://t.me/Sopha_m1'}></Link>Остались вопросы?</Typography>
            <Typography>
              Если у вас остались вопросы, то вы можете задать его по почте –{' '}
              <span style={{borderBottom: '1px solid #707895'}}>
                <a href="mailto: partners@bi-tu-bi.ru">partners@bi-tu-bi.ru</a>
              </span>
            </Typography>
          </Box>
        </Box>
        <Box className={s.buttonGroup}>
          <Button
            variant="contained"
            sx={{
              color: 'white',
              width: '30%',
              height: '44px',
              fontSize: '16px',
              marginRight: '30px',
            }}
            onClick={handleIconClose}
            className={s.sendButton}
          >
            Отправить
          </Button>
          <Button
            variant="contained"
            sx={{
              color: 'black',
              width: '30%',
              height: '44px',
              fontSize: '16px',
            }}
            style={{backgroundColor: 'white', boxShadow: 'none'}}
            onClick={() => navigate('/register')}
          >
            Регистрация
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalHowDItWork;
