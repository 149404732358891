import React from 'react';
import {createSlice} from '@reduxjs/toolkit';

import {ModalDefaultParamsI} from '../../components/modal/modalComponent';


const initialState: ModalDefaultParamsI = {
  title: '',
  acceptButton: {
    text: 'accept',
    callback: () => undefined,
    type: 'accept',
  },
  declineButton: {
    text: 'decline',
    callback: () => undefined,
    type: 'decline',
  },
  children: <div></div>,
  isOpened: false,
};


const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    updateModal: (state = initialState, action) => {
      return {
        ...state,
        title: action.payload.title,
        acceptButton: action.payload.acceptButton,
        declineButton: action.payload.declineButton,
        decayTimeout: action.payload.decayTimeout,
        children: action.payload.children,
        isOpened: true,
      };
    },
    closeModal: (state = initialState) => {
      return {
        ...state,
        title: '',
        acceptButton: undefined,
        declineButton: undefined,
        decayTimeout: undefined,
        children: <div></div>,
        isOpened: false,
      };
    },
  },

});

const modalReducer = modalSlice.reducer;

export const {updateModal, closeModal} = modalSlice.actions;
export default modalReducer;
