import React, {FC, useEffect} from 'react';
import RootPage from './rootPage';
import '../static/scss/index.scss';
import {APIurl} from '../utils/globals';
import Slogan from '../components/slogan';
import NewsCard from '../components/cards_homepage/NewsCard';
import Footer from '../components/Footer';
import SideWindow from '../components/SideWindow/SideWindow';
import ProfileField from '../components/ProfileField';
import newsImg3 from '../static/profilepage/imgForNews/3.jpg';
import newsImg2 from '../static/profilepage/imgForNews/2.jpg';
import newImg1 from '../static/categoriesPics/8.jpg';
import {useLocation} from 'react-router';
import axios from 'axios';
import CustomSnackbarBalance from '../components/Snackbar/SnackbarBalance';

const ProfilePage: FC = () => {
  const [userData, setUserData] = React.useState<any>(null);
  const location = useLocation().pathname;
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const [isEditable, setIsEditable] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [owner, setOwner] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [itn, setItn] = React.useState('');
  const sideWindowOptions = [
    {option: 'Информация об организации', link: '/profile'},
    {option: 'Добавить объявление', link: '/product/add'},
    {option: 'Чат с закачиком', link: '/chat'},
    {option: 'Мои товары', link: `../company/${userData?.data?.company?.id}`},
    {option: 'Пополнить баланс', link: `#`},
  ];

  const [openSnackbarSuccess, setOpenSnackbarSuccess] = React.useState(false);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${APIurl}/user/me`, {
        withCredentials: true,
        method: 'GET',
      });
      setIsLoading(true);
      if (response.status === 200) {
        const userData = await response.data;
        setUserData(userData);
        setIsAuthorized(true);
      } else if (response.status === 401) {
        setIsAuthorized(false);
      } else {
        console.error('Error:', response.status);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // if (isAuthorized) {
    fetchUserData();
    // }
  }, [isLoading, location]);

  useEffect(() => {
    const checkPayment = axios
      .post(
        `${APIurl}/user/payment/handle/all`,
        {method: 'POST'},
        {withCredentials: true},
      )
      .then((res) => {
        if (res.data.data) {
          setOpenSnackbarSuccess(true);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  // if (isLoading) {
  //   return (
  //     <h1 className="min-w-screen min-h-screen flex justify-center items-center">
  //       Загрузка... (При необходимости перезагрузите страницу)
  //     </h1>
  //   );
  // }
  const handleTelegramResponse = (response: any) => {
    console.log(response);
  };
  return (
    <RootPage nameOfActive="Profile">
      <Slogan />
      <main className="flex justify-center gap-[16px] mt-[45px]">
        {userData ? (
          <>
            <SideWindow
              nameOfActive={'Информация об организации'}
              linkOptions={sideWindowOptions}
              legalName={userData?.data.company.legal_name}
            />
            <ProfileField
              data={userData.data}
              isEditableState={[isEditable, setIsEditable]}
              emailState={[email, setEmail]}
              ownerState={[owner, setOwner]}
              itnState={[itn, setItn]}
              descriptionState={[description, setDescription]}
            />
          </>
        ) : (
          <h1 className="min-w-screen min-h-screen flex justify-center items-center">
            Загрузка... (При необходимости перезагрузите страницу)
          </h1>
        )}
      </main>
      <section className="flex flex-col justify-center items-center">
        <h2 className="text-[36px] text-[#272121] mb-[16px] font-semibold">
          Бизнес советы и новости индустрии{' '}
        </h2>
        <h4 className="text-[#707895] text-[20px]">
          Подборка полезных статей с советами и новостями, которые помогут
          развиваться вашему бизнесу.
        </h4>
        <div className="flex gap-2 flex-wrap">
          <NewsCard
            title={'Обзор рынка строительных материалов в России в 2023 году'}
            date={'22 августа 2023'}
            link="https://vc.ru/trade/805190-obzor-rynka-stroitelnyh-materialov-v-rossii-v-2023-godu"
            img={newImg1}
          />
          <NewsCard
            title={'46% россиян изменили подход к покупке стройматериалов'}
            date={'6 декабря 2022'}
            link="https://www.retail.ru/news/46-rossiyan-izmenili-podkhod-k-pokupke-stroymaterialov-6-dekabrya-2022-223412/"
            img={newsImg2}
          />
          <NewsCard
            title={'Чего ожидать от рынка строительных материалов в 2022 году?'}
            date={'23 августа 2023'}
            link="https://optitraderus.ru/articles/chego-ozhidat-ot-rynka-stroitelnyh-materialov-v-2022-godu/"
            img={newsImg3}
          />
        </div>
      </section>
      <Footer />
      <CustomSnackbarBalance
        open={openSnackbarSuccess}
        setOpen={setOpenSnackbarSuccess}
        variant="success"
      />
    </RootPage>
  );
};

export default ProfilePage;
