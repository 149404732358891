import React from 'react';
import style from './cardStyle.module.scss';
import {GoodT} from '../../redux/reducers/categoriesReducer';
import star from '../../static/catalogPage/star.svg';
import {Button} from '@mui/material';
import Ajax from '../../api/ajax';
import {useNavigate} from 'react-router';
interface Props {
  card: GoodT;
  currentUser: any;
}

export default function CardMobile({card, currentUser}: Props) {
  const navigate = useNavigate();

  function handleClickBuy() {
    return currentUser !== null ?
      Ajax.post({
        url: `/chat/init`,
        body: {
          sender_id: currentUser.id,
          product_id: card.id,
        },
      }).then(({status, response}) => {
        if (status === Ajax.STATUS.ok) {
          if ((response?.data as any).create_new_chat == false) {
            navigate(`/chat/${card.id}`);
          } else {
            navigate(`/chat`);
          }
          console.log(response.data);
        } else {
          console.warn('error');
        }
      }) : navigate('/register');
  }
  return (
    <div className={style.card}>
      <img
        src={card.photo ? card.photo[0] : ''}
        alt="img_card"
        className={style.card__img}
      />
      <div className={style.card__info}>
        <div className={style.card__infoWrapper}>
          <div className={style.card__title}>{card.name}</div>
          <div className={style.card__company}>{card.company?.legal_name}</div>
          <div className={style.card__rating}>
            <img src={star} alt="rating" className={style.card__ratingIcon} />
            <div>4,5</div>
          </div>
          <div className={style.card__price}>{card.price} ₽</div>
        </div>
        <Button
          sx={{
            textTransform: 'none',
            backgroundColor: '#5075F9',
            color: '#FFFFFF',
          }}
          className={style.card__button}
          onClick={() => {
            handleClickBuy();
          }}
        >
          <div className={style.card__buttonText}> Купить</div>
        </Button>
      </div>
    </div>
  );
}
