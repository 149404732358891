import React from 'react';

import {Avatar} from '@mui/material';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

interface UserAvatarProps {
    nameUser: string | undefined
    surnameUser: string | undefined,
  }


function UserAvatar({nameUser, surnameUser}: UserAvatarProps) {
  return (
    <Avatar
      {...stringAvatar(nameUser + ' ' + surnameUser)}
      sx={{
        width: 30,
        height: 30,
        fontSize: '10px',
        fontWeight: 400,
        bgcolor: '#C8D8F5',
        color: '#1B2B62',
      }}
    />
  );
}

export default UserAvatar;
