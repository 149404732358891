import React from 'react';
import RadioGroup from '../NewInputs/RadioGroup';
import info from '../../static/productpage/info.svg';
import transHistory from '../../static/productpage/transHistory.svg';

interface SideWindowT {
  nameOfActive: string;
  linkOptions: {option: string; link: string}[];
  legalName: string;
}

const SideWindow: React.FC<SideWindowT> = ({
  nameOfActive,
  linkOptions,
  legalName,
}) => {
  const [selected, setSelected] = React.useState(nameOfActive);
  return (
    <aside
      className="flex flex-col px-[23px] py-[20px] rounded-[12px] h-fit"
      style={{
        boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.07)',
        background: '#F4F8FF',
      }}
    >
      <h6 style={{color: '#272121', fontSize: '16px', fontWeight: '500'}}>
        {legalName === '' ? null : <b>{legalName}</b>}
      </h6>
      <div
        className="min-w-full h-[1px] my-[17px]"
        style={{background: 'rgba(29, 54, 83, 0.17)'}}
      />
      <RadioGroup
        linkOptions={linkOptions}
        imgs={[info, transHistory, info, info, info]}
        selectedValue={selected}
        onChange={(e) => setSelected(e)}
      />
      <footer className="bottom-0 mt-[60px] flex flex-col items-center">
        <div
          className="min-w-full h-[1px] mb-[12px]"
          style={{background: 'rgba(29, 54, 83, 0.17)'}}
        />
        <a
          href="https://t.me/Sopha_m1"
          style={{
            color: 'var(--text-link, #3E74DD)',
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          Остались вопросы?
        </a>
      </footer>
    </aside>
  );
};

export default SideWindow;
