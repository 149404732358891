export const mockDataCard = [
  {
    id: 1,
    title: 'Быстро',
    subtitle: [
      {
        id: 1,
        text: 'Онлайн общение в чате',
      },
      {
        id: 2,
        text: 'Поиск с фильтрами',
      },
      {
        id: 3,
        text: 'Рекомендации',
      },
      {
        id: 4,
        text: 'Доставка',
      },
    ],
  },
  {
    id: 2,
    title: 'Удобно',
    subtitle: [
      {
        id: 1,
        text: 'Группировка чатов по товарам и стадиям оплаты клиента',
      },
      {
        id: 2,
        text: 'Поиск с фильтрами',
      },
    ],
  },
  {
    id: 3,
    title: 'Выгодно',
    subtitle: [
      {
        id: 1,
        text: 'Товары напрямую от поставщиков',
      },
      {
        id: 2,
        text: 'Доставка',
      },
    ],
  },
  {
    id: 4,
    title: 'Прозрачно',
    subtitle: [
      {
        id: 1,
        text: 'Сохранение истории переписки в чате',
      },
      {
        id: 2,
        text: 'Служба поддержки',
      },
    ],
  },
];
