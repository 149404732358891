import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import UserAvatar from '../UserAvatar';
import style from './message.module.scss';
import {MessageType, MessagesData, currentUserType} from '../types';

interface MessageProps {
  messages: MessageType | MessagesData;
  currentUser: currentUserType;
}

export default function Message({messages, currentUser}: MessageProps) {
  if (!messages) {
    return null;
  } else {
    return (
      <Box
        className={
          // eslint-disable-next-line no-constant-condition
          currentUser && currentUser.id == messages.sender_id ?
            `${style.message}` + ' ' + `${style.message__messageRight}` :
            `${style.message}`
        }
      >
        <Stack className={style.message__title} flexDirection={'row'}>
          <UserAvatar
            nameUser={messages?.sender_name.slice(0, 1)}
            surnameUser={messages?.sender_name.slice(1, 2)}
          />
          <Typography className={style.message__user}>

            {
            // TODO: данные отправителя и получателя в messages не меняется
            // Кто отправитель и получатель, можно понять только сравнивая с CurrentUser
            // Захаркодил конструкцию проверки и отображения имен правильно.
            // Подумать как сделать это более читабельно
              currentUser && currentUser.id == messages.sender_id ?
                currentUser.name == messages.sender_name ?
                  messages.sender_name :
                  currentUser.name :
                currentUser && currentUser.name == messages.sender_name ? messages.receiver_name :
                  messages.sender_name }
          </Typography>
        </Stack>
        <Typography className={style.message__content}>
          {messages?.text}
        </Typography>
      </Box>
    );
  }
}
