/* eslint-disable */
import {Button} from '@mui/material';
import React, {FormEvent} from 'react';
import exit from '../../static/profilepage/exit.svg';
import edit from '../../static/profilepage/editData.svg';
import check from '../../static/profilepage/check.svg';
import cross from '../../static/profilepage/cross.svg';
import TextInput from '../NewInputs/TextInput';
import {APIurl} from '../../utils/globals';
import {useNavigate} from 'react-router';
import axios from 'axios';
import TelegramLoginButton, {TelegramUser} from 'telegram-login-button';
interface CompanyT {
  description: string;
  id: number;
  name: string;
  legal_name: string;
  itn: string;
  psrn: string;
  address: string;
  legal_address: string;
  email: string;
  phone: string;
  link: string;
  activity: string;
  owner_id: string;
  rating: string;
  verified: string;
}

interface CompanyUserT {
  post: string;
  company_id: string;
  user_id: string;
  itn: string;
}

interface ProfileT {
  patronymic: string;
  id: number;
  name: string;
  surname: string;
  avatar: string;
  email: string;
  description: string;
  company: CompanyT;
  companyUser: CompanyUserT;
  balance: string | number;
}

type StateTuple<T> = [T, React.Dispatch<React.SetStateAction<T>>];
type ProfileFieldT = {
  data: ProfileT;
  isEditableState: StateTuple<boolean>;
  emailState: StateTuple<string>;
  ownerState: StateTuple<string>;
  itnState: StateTuple<string>;
  descriptionState: StateTuple<string>;
};

const ProfileField: React.FC<ProfileFieldT> = ({data, isEditableState}) => {
  const [initName, setInitName] = React.useState(data?.name);
  const [initSurname, setInitSurname] = React.useState(data?.surname);
  const [initPatronymic, setInitPatrionymic] = React.useState(data?.patronymic);
  const [initCompanyName, setInitCompanyName] = React.useState(
    data?.company.name,
  );
  const [initAdress, setInitAdress] = React.useState(data?.company.address);
  const [initLegalAdress, setInitLegalAdress] = React.useState(
    data?.company.legal_address,
  );
  const [initPhone, setInitPhone] = React.useState(data?.company.phone);
  const [initDescription, setInitDescription] = React.useState(
    data?.company.description,
  );
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    axios
      .patch(
        `${APIurl}/profile`,
        {
          owner: {
            name: initName,
            surname: initSurname,
            patronymic: initPatronymic,
            email: '',
          },
          company: {
            name: initName,
            description: initDescription,
            address: initAdress,
            legal_address: initLegalAdress,
            itn: data.company.itn,
            phone: initPhone,
            link: '',
            activity: '',
          },
          post: '',
        },
        {withCredentials: true},
      )
      .then((res) => {
        if (res.status === 200) {
          isEditableState[1](false);
        }
      });
  };
  const navigate = useNavigate();
  return (
    <section
      className="flex p-[40px] flex-col rounded-[12px] h-fit"
      style={{boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.07)'}}
    >
      <div className="flex justify-between items-center gap-14">
        <h2 style={{color: '#272121'}} className="font-semibold text-[28px]">
          Информация об{' '}
          <span style={{color: '#3E74DD'}}>{data?.company.legal_name}</span>
        </h2>
        <div className="flex flex-col align-center gap-[10px]">
          <div>Баланс: {data?.balance} рублей</div>
          <TelegramLoginButton
            botName="BituBi_Chat_bot"
            dataOnauth={(user: TelegramUser) => console.log(user)}
          />
          <Button
            className="flex items-center !px-[14px] !py-[10px] gap-[28px]"
            style={{
              borderRadius: '8px',
              opacity: '0.8',
              background: 'rgba(112, 120, 149, 0.15)',
            }}
            onClick={() => {
              fetch(`${APIurl}/logout`, {
                method: 'DELETE',
                credentials: 'include',
                // headers: {
                //   'Access-Control-Allow-Origin': 'true',
                // },
              });
              navigate('/');
            }}
          >
            <img src={exit} alt="exit" />
            <h5
              className="text-[#272121] flex items-center !my-0 h-fit"
              style={{fontSize: '14px', textAlign: 'center'}}
            >
              Сменить аккаунт
            </h5>
          </Button>
        </div>
      </div>
      {isEditableState[0] ? (
        <form onSubmit={handleSubmit}>
          <TextInput state={[initName, setInitName]} label="Имя:" />
          <TextInput state={[initSurname, setInitSurname]} label="Фамилия:" />
          <TextInput
            state={[initPatronymic, setInitPatrionymic]}
            label="Отчество:"
          />
          <TextInput
            state={[initPhone, setInitPhone]}
            label="Номер телефона:"
          />
          <TextInput
            state={[initCompanyName, setInitCompanyName]}
            label="Название компании:"
          />
          <TextInput state={[initAdress, setInitAdress]} label="Адрес:" />

          <TextInput
            state={[initLegalAdress, setInitLegalAdress]}
            label="Юридическое лицо:"
          />
          <TextInput
            state={[initDescription, setInitDescription]}
            label="Описание Организации:"
          />
          <div className="flex gap-4">
            <Button
              className="flex gap-[8px] rounded[8px] !bg-[#61B43A] opacity-[0.9] px-[14px] py-[11px] !text-white"
              type="submit"
            >
              <img src={check} alt="check" />
              Сохранить
            </Button>
            <Button
              onClick={() => isEditableState[1](false)}
              className="flex gap-[8px] !text-[#DF2E38]"
            >
              <img src={cross} alt="cross" />
              Отмена
            </Button>
          </div>
        </form>
      ) : (
        <>
          <h4 className="text-[#707895] !text-[20px]">
            Email: <span className="text-[#272121]">{data?.email}</span>
          </h4>
          <h4 className="text-[#707895] !text-[20px]">
            Владелец:{' '}
            <span className="text-[#272121]">
              {data?.surname} {data?.name} {data?.patronymic}
            </span>
          </h4>
          <h4 className="text-[#707895] !text-[20px]">
            ИНН Организации:{' '}
            <span className="text-[#272121]">{data?.company.itn}</span>
          </h4>
          <h4 className="text-[#707895] !text-[20px]">
            Название компании:{' '}
            <span className="text-[#272121]">{data?.company.name}</span>
          </h4>
          <h4 className="text-[#707895] !text-[20px]">
            Телефон:{' '}
            <span className="text-[#272121]">{data?.company.phone}</span>
          </h4>
          <h4 className="text-[#707895] !text-[20px]">
            Адрес:{' '}
            <span className="text-[#272121]">{data?.company.address}</span>
          </h4>
          <h4 className="text-[#707895] !text-[20px]">
            Юридическое лицо:{' '}
            <span className="text-[#272121]">{data?.company.legal_name}</span>
          </h4>
          <h4 className="text-[#707895] !text-[20px]">
            Описание:{' '}
            <span className="text-[#272121]">{data?.company.description}</span>
          </h4>
          <div
            className="flex gap-2 items-center cursor-pointer mt-2"
            onClick={() => isEditableState[1](true)}
          >
            <img src={edit} alt="edit-data" />
            <h6 className="!text-[#272121] !text-[14px]">
              Редактировать данные
            </h6>
          </div>
        </>
      )}
    </section>
  );
};

export default ProfileField;
