/* eslint-disable */
import React from 'react';
import s from './info.module.scss';
import ModalHowDItWork from '../../../../components/Modals/HowDoesItWork';
import {Box, Button, Typography, useMediaQuery} from '@mui/material';
import FormsModal from '../../../../components/Modals/FormsModal';

const Info: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const handleIconClose = () => {
    setOpen(false);
  };
  const handleIconClose2 = () => {
    setOpen2(false);
  };

  const matches = useMediaQuery('(max-width: 768px)');
  const matchesMobile = useMediaQuery('(max-width: 576px)');
  return (
    <Box component="main" className={s.main}>
      <Box style={!matchesMobile ? {height: 322} : {}} className={s.inner_main}>
        <Typography
          color={'white'}
          fontSize={matchesMobile ? 22 : 36}
          fontWeight="bold"
        >
          битуби
        </Typography>
        <Typography
          color={'white'}
          fontSize={20}
          style={
            matchesMobile
              ? {width: 'auto', marginBottom: '31px', fontSize: 12}
              : {width: '412px', marginBottom: '31px'}
          }
        >
          платформа для поиска поставщиков строительных материалов.
        </Typography>
        {matches ? (
          <Button
            style={{
              backgroundColor: '#558AF0',
              color: 'white',
              minWidth: '95%',
              fontSize: matchesMobile ? 10 : 'auto',
              textTransform: 'none',
            }}
            onClick={handleOpen2}
          >
            Оставить заявку бесплатно
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{color: 'black', width: '65%', height: '44px'}}
            style={{backgroundColor: 'white', fontWeight: 'bold'}}
            onClick={handleOpen}
          >
            Как это работает?
          </Button>
        )}
        <FormsModal
          open={open2}
          handleClose={handleClose2}
          handleIconClose={handleIconClose2}
          isMobile={matches ? true : false}
        />
        <ModalHowDItWork
          open={open}
          handleClose={handleClose}
          handleIconClose={handleIconClose}
          isMobile={matches ? true : false}
        />
      </Box>
    </Box>
  );
};

export default Info;
