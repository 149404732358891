import {Avatar, Box, IconButton, Typography} from '@mui/material';
import logo from '../../static/landing/logo.svg';
import React, {useEffect, useState} from 'react';
import avatar from '../../static/landing/avatar.svg';
import {useNavigate} from 'react-router';
import {NavLink} from 'react-router-dom';
import s from './header.module.scss';
import {APIurl} from '../../utils/globals';

const HeaderComponent: React.FC = () => {
  const navigate = useNavigate();
  const [isAuthed, setIsAuthed] = useState(false);
  useEffect(() => {
    fetch(`${APIurl}/user/me`, {credentials: 'include'}).then((res) =>
      res.status === 401 ? setIsAuthed(false) : setIsAuthed(true),
    );
  }, []);
  return (
    <Box
      component="header"
      className={s.header}
      style={{color: '#272121', height: '90px'}}
    >
      <Box className={s.header__left}>
        <IconButton onClick={() => navigate('/')} style={{marginRight: '10px'}}>
          <Avatar
            src={logo}
            alt="Logo"
            style={{width: '60px', height: '60px'}}
          />
        </IconButton>
        <NavLink to={'/categories'}>Товары</NavLink>
        <NavLink to={'/about'}>О нас</NavLink>
        <NavLink to={'/product/add'}>Купить/Продать</NavLink>
      </Box>
      <Box className={s.header__right}>
        <a href={'https://t.me/Sopha_m1'}>Менеджер</a>
        <a href={'/chat'}>Чат</a>
        <Box className={s.header__right__profile}>
          <IconButton
            onClick={() =>
              isAuthed ? navigate('/profile') : navigate('/register')
            }
          >
            <Avatar src={avatar} sx={{width: '25px', height: '25px'}} />
          </IconButton>
          {isAuthed ? (
            <Typography>Личный кабинет</Typography>
          ) : (
            <Typography>Войти</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderComponent;
