import {Box, Typography, Stack} from '@mui/material';
import React from 'react';
import {dataLogoMok} from './constants';

export default function Partners() {
  return (
    <Box
      sx={{
        width: '100%',
        margin: '30px auto',
        backgroundColor: '#F1F5FD',
      }}
    >
      <Stack
        sx={{
          width: '1244px',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '33px',
          padding: '70px 0 65px',
        }}
      >
        <Typography
          fontSize={'36px'}
          fontWeight={'600'}
          sx={{color: '#5075F9'}}
        >
          Наши партнеры
        </Typography>
        <Stack display="flex" flexDirection={'row'} flexWrap={'wrap'} gap={'50px'} justifyContent={'space-between'}>
          {dataLogoMok.map((item) => {
            return (
              <Box
                key={item.id}
                sx={{
                  // width: '400px',
                  // height: '300px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={item.pic} alt="партнер" />
              </Box>
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
}
