// import MainPage from '../pages/mainPage';
import FastOrderPage from '../pages/fastOrderPage';
import FastRegisterPage from '../pages/fastRegisterPage';
import ProfilePage from '../pages/profilePage';
import CatalogPage from '../pages/catalogPage';
import ItemPage from '../pages/itemPage';
import TestPage from '../pages/testPage';
import Landing from '../pages/landing';
import {RouterMapT} from '../components/router/routerTypes';
import type {NameLinksMapT} from './appRoutingHeaderConfig';
import CartPage from '../pages/cartPage';
import HomePage from '../pages/HomePage';
import AddProduct from '../pages/Product/AddProduct';
import Chats from '../pages/ChatPage/';
import AddFileProduct from '../pages/Product/AddFileProduct';
import ChatPage from '../pages/chatPage';
import CategoriesPage from '../pages/categoriesPage';
import AboutPage from '../pages/AboutPage/index';
import CompanyPage from '../pages/companyPage';

// //////////////////////////////////////////////////////
//
//              Конфиг приложения: paths
//
// //////////////////////////////////////////////////////

const AppRoutingMap: RouterMapT<NameLinksMapT> = {
  AddProduct: {
    link: '/product/add',
    Component: AddProduct,
    // isAuthRequired: true,
    isAuthRequired: false,
  },
  ProductById: {
    link: '/product/:id',
    Component: AddProduct,
    // isAuthRequired: true,
    isAuthRequired: false,
  },
  Categories: {
    link: '/categories',
    Component: CategoriesPage,
    isAuthRequired: false,
  },
  Company: {
    link: '/company/:id',
    Component: CompanyPage,
    isAuthRequired: false,
  },
  CategoryPage: {
    link: '/categories/:catgeoryName',
    Component: CatalogPage,
    isAuthRequired: false,
  },

  Main: {
    link: '/',
    Component: HomePage,
    // isAuthRequired: true,
    isAuthRequired: false,
  },
  Landing: {
    link: '/main',
    Component: Landing,
    // isAuthRequired: true,
    isAuthRequired: false,
  },
  About: {
    link: '/about',
    Component: AboutPage,
    isAuthRequired: false,
  },
  Auth: {
    link: '/login',
    Component: FastRegisterPage,
    isAuthRequired: false,
  },
  Register: {
    link: '/register',
    Component: FastRegisterPage,
    isAuthRequired: false,
  },
  Order: {
    link: '/order',
    Component: FastOrderPage,
    // isAuthRequired: true,
    isAuthRequired: false,
  },
  Profile: {
    link: '/profile',
    Component: ProfilePage,
    isAuthRequired: false,
  },
  // Catalog: {
  //   link: '/catalog',
  //   Component: CatalogPage,
  //   isAuthRequired: false,
  // },
  Cart: {
    link: '/cart',
    Component: CartPage,
    isAuthRequired: true,
  },
  Item: {
    link: '/categories/:catgeoryName/:id',
    Component: ItemPage,
    isAuthRequired: false,
  },
  Chat: {
    link: '/chat/:id',
    Component: Chats,
    isAuthRequired: false,
  },
  Chats: {
    link: '/chat',
    Component: Chats,
    isAuthRequired: false,
  },
  // 'Chats': {
  //   link: '/chats',
  //   Component: ChatPage,
  //   isAuthRequired: false,
  // },
  File: {
    link: '/product/add/file',
    Component: AddFileProduct,
    isAuthRequired: false,
  },
  // 'Test': {
  //   link: '/test',
  //   Component: TestPage,
  //   isAuthRequired: false,
  // },
} as const;

export default AppRoutingMap;
