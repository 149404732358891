/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, {
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
  MouseEvent,
} from 'react';

import useMaterialize from '../../hooks/useMaterialize';

import './carousel.scss';

export interface MaterializeCarouselI {
  el: HTMLDivElement; // The DOM element the plugin was initialized with.
  options: Record<string, unknown>; // The options the instance was initialized with.
  pressed: boolean; // If the carousel is being clicked or tapped.
  dragged: boolean; // If the carousel is currently being dragged.
  center: number;
  next: (n?: number) => void;
  prev: (n?: number) => void;
  set: (n?: number) => void;
  destroy: () => void;
  _handleCarouselDragBound: () => void;
  _handleCarouselTapBound: () => void,
  _handleCarouselReleaseBound: () => void;
}

interface СarouselPropsI {
  isSlider?: boolean;
  duration?: number;
  dist?: number;
  shift?: number;
  padding?: number;
  numVisible?: number;
  fullWidth?: boolean;
  indicators?: boolean;
  noWrap?: boolean;
  onCycleTo?: (slide: HTMLElement, dragged: boolean) => void;
  FixedComponent?: FC;
  children?: ReactNode[] | ReactNode;
  className?: string;
  itemClassName?: string;
  // setCarouselRootRef?: (ref: React.RefObject<HTMLDivElement>) => void,
  setCarouselInstance?: (carousel: MaterializeCarouselI) => void;
  ref?: React.RefObject<HTMLDivElement>;
  disableMouseDrag?: boolean;
}

/*
// put .prev, .next, .set and .destroy classes on inner components to manage carousel state
// prev, prev-1, prev-2 <=> prev(), prev(1), prev(2)
// prev-invalid-number <=> prev()
// same for next and set

// height matches images' height if fullWidth === true
// numVisible does not make sence when usSlider === true

// more about materialize carousel
// https://codepen.io/nubtehy/pen/WyXveP
// https://materializecss.com/carousel.html
*/
const СarouselComponent: FC<СarouselPropsI> = (props) => {
  const {isSlider, indicators, FixedComponent, className, itemClassName, setCarouselInstance, disableMouseDrag} = props;
  const children = Array.isArray(props.children) ?
    props.children :
    props.children ?
      [props.children] :
      undefined;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ref = 'ref' in props && props.ref !== undefined ? props.ref : useRef<HTMLDivElement>(null);

  const M = useMaterialize();
  const [carouselInstance, _setCarouselInstance] = useState<MaterializeCarouselI | null>(null);

  useEffect(
    () => {
      const instance: MaterializeCarouselI = M.Carousel.init(ref.current, props);
      typeof setCarouselInstance === 'function' ? setCarouselInstance(instance) : '';
      _setCarouselInstance(instance);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // disable drag
  if (disableMouseDrag) {
    carouselInstance?.el.removeEventListener('mousedown', carouselInstance?._handleCarouselTapBound);
    // carouselInstance?.el.removeEventListener('mousemove', carouselInstance?._handleCarouselDragBound);
    // carouselInstance?.el.removeEventListener('mouseup', carouselInstance?._handleCarouselReleaseBound);
    // carouselInstance?.el.removeEventListener('mousedown', carouselInstance?._handleCarouselTapBound);
  }

  const clickChecker = (e: MouseEvent<HTMLElement>) => {
    const carouselInstance = M.Carousel.getInstance(ref.current);
    const classList = (e.target as HTMLElement).classList;

    let manageClass = '';
    classList.forEach((el) =>
      el.startsWith('next') ? (manageClass = el) : '',
    );
    if (manageClass) {
      // console.log('next', Number(manageClass.slice(5)) || 1);
      carouselInstance.next(Number(manageClass.slice(5)) || 1);
    }

    manageClass = '';
    classList.forEach((el) =>
      el.startsWith('prev') ? (manageClass = el) : '',
    );
    if (manageClass) {
      // console.log('prev', Number(manageClass.slice(5)) || 1);
      carouselInstance.prev(Number(manageClass.slice(5)) || 1);
    }

    manageClass = '';
    classList.forEach((el) => (el.startsWith('set') ? (manageClass = el) : ''));
    if (manageClass) {
      // console.log('set', Number(manageClass.slice(4)) || 0);
      carouselInstance.set(Number(manageClass.slice(4)) || 0);
    }

    manageClass = '';
    classList.forEach((el) => (el === 'destroy' ? (manageClass = el) : ''));
    if (manageClass) {
      carouselInstance.destroy();
    }
  };

  return (
    <div
      className={
        'carousel theme' +
        (isSlider ? ' carousel-slider' : '') +
        ' ' +
        (className || '')
      }
      data-indicators={indicators}
      ref={ref}
      onClick={clickChecker}
    >
      {FixedComponent ? (
        <div className={'carousel-fixed-item ' + (itemClassName || '')}>
          <FixedComponent />
        </div>
      ) : (
        ''
      )}

      {Array.isArray(children) ?
        children?.map((child, idx) => (
          <div className={'carousel-item ' + (itemClassName || '')} key={idx}>
            {child}
          </div>
        )) : ''
      }
    </div>
  );
};

export const getSlideIndex = (slide: HTMLElement) =>
  Array.from((slide.parentElement as HTMLElement).childNodes).findIndex(
    (child) => child === slide,
  );

export default СarouselComponent;
