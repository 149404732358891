import React, {
  FC,
  useState,
  FormEvent,
  FormEventHandler,
  useEffect,
} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import LoginForm from './forms/login';
import RegisterForm, {Company} from './forms/register';

import Ajax from '../api/ajax';

import useMaterialize from '../hooks/useMaterialize';

import store from '../redux/store';
import {AuthActions} from '../redux/reducers/authReducer';

import type {HistoryStateI} from '../components/router/routerTypes';

import '../static/scss/index.scss';
import FastRegisterLanding from './landings/fastRegister';
import {Box, useMediaQuery} from '@mui/material';

const FastRegistrationPage: FC = () => {
  // с их помощью либо делаем редирект на то место, где требовалась
  // аутентификация, либо на главную
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [patronymic, setPatronymic] = useState('');
  const [country, setCountry] = useState('');
  const [post, setPost] = useState('');
  const [itn, setItn] = useState('');
  const [uniqEmail, setUniqEmail] = useState(false);
  const [company, setCompany] = useState<Company>({
    legalName: '',
    brandName: '',
    address: '',
    opf: '',
  });
  const [srvErr, setSrvErr] = useState('');
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(true);

  const M = useMaterialize();

  const isRegister = location.pathname.startsWith('/register');

  const matches = useMediaQuery('(max-width: 768px)');

  useEffect(() => M.updateTextFields());

  const registerSubmit: FormEventHandler<HTMLFormElement> = (
    event: FormEvent,
  ) => {
    event.preventDefault();
    Ajax.post({
      url: '/fastregister',
      body: {
        name: company.brandName.trim(),
        legal_name: company.legalName.trim(),
        itn: itn,
        email,
        password,
        owner_name: firstName,
        surname,
        patronymic,
        country: '_',
        post: post.trim(),
      },
    }).then(({status, response}) => {
      if (status === Ajax.STATUS.ok) {
        // TODO: проработать сущность юзера на бэке
        // store.dispatch(AuthActions.login(response.data as FastRegisterResponseI));
        // store.dispatch(
        //   AuthActions.login({
        //     // TODO: нейймнг полей
        //     name: company.brandName,
        //     legal_name: '_',
        //     itn,
        //     email,
        //     password,
        //     owner_name: firstName,
        //     surname,
        //     patronymic,
        //     country: '_',
        //     post,
        //   }),
        // );
        navigate('/profile');
      } else {
        console.warn(response.msg);
        setSrvErr(response.msg);
      }
    });
  };

  const loginSubmit: FormEventHandler<HTMLFormElement> = (event: FormEvent) => {
    event.preventDefault();
    Ajax.post({
      url: '/user/login',
      body: {
        email,
        password,
      },
    }).then(({status, response}) => {
      if (status === Ajax.STATUS.ok) {
        // TODO:
        // store.dispatch(AuthActions.login(response.data as UserLoginResponseI));
        // if ((location.state as HistoryStateI)?.from) {
        //   navigate((location.state as HistoryStateI).from);
        // } else {
        navigate('/profile');
        // }
        console.log(response.data);
      } else {
        console.warn(response.msg);
        setSrvErr(response.msg);
      }
    });
  };

  // TODO: проверить, что происходит со стейтом isRememberMeChecked при смене типа формы
  return (
    // <div className={'screen theme-blue mv-0 ' + matches ? '' : ' row'}>
    <>
      {/* <Header /> */}
      <div className="screen theme-registr mv-0 row">
        {matches ? null : (
          <div className="screen col s7 fast-register__landing">
            <FastRegisterLanding />
          </div>
        )}
        {/* style={matches ? "width: 100% !important" : "width: 40%"} */}
        <Box
          className={'screen s5 theme-white col'}
          width={matches ? '100%' + '!important' : ''}
        >
          <div className="container">
            <h3 className="theme-color">&nbsp;</h3>

            {isRegister ? (
              <RegisterForm
                handleSubmit={registerSubmit}
                srvErr={srvErr}
                manageEmailState={[email, setEmail]}
                managePasswordState={[password, setPassword]}
                manageRepeatPasswordState={[repeatPassword, setRepeatPassword]}
                isRememberMeChecked={isRememberMeChecked}
                setIsRememberMeChecked={setIsRememberMeChecked}
                manageSurnameState={[surname, setSurname]}
                manageFirstnameState={[firstName, setFirstName]}
                managePatronymicState={[patronymic, setPatronymic]}
                manageCountryState={[country, setCountry]}
                managePositionState={[post, setPost]}
                manageITNState={[itn, setItn]}
                manageCompanyState={[company, setCompany]}
                manageUniqueEmail={[uniqEmail, setUniqEmail]}
              />
            ) : (
              <LoginForm
                handleSubmit={loginSubmit}
                manageEmailState={[email, setEmail]}
                managePasswordState={[password, setPassword]}
                srvErr=""
                isRememberMeChecked={isRememberMeChecked}
                setIsRememberMeChecked={setIsRememberMeChecked}
              />
            )}
          </div>
        </Box>
      </div>
    </>
  );
};

export default FastRegistrationPage;
