import type {ApiResponseLoginI} from '../redux/reducers/authReducer';
import type {ResponsesT} from '../api/messageTypes';

const mockMap: ResponsesT = {
  '/user/login': {
    token: '1337',
    login: 'tester',
    firstName: 'Денис',
    lastName: 'Кириллов',
    email: 'hello@example.com',
  } as ApiResponseLoginI,
  // TODO: прописать моки
  '/user/register': {
    token: '_jwt_',
  },
  '/company/register': {
  },
  '/fastregister': {
  },
  '/user/check': {
  },
  '/search/categories': [
    {
      id: 1,
      name: 'тестовая категория 1',
      description: {
        String: '',
        Valid: true,
      },
    }, {
      id: 1,
      name: 'тестовая категория 2',
      description: {
        String: '',
        Valid: true,
      },
    },
  ],
};
  // 'user/register': UserRegisterResponseI,
  // 'company/register': CompanyRegisterResponseI,
  // 'fastregister': FastRegisterResponseI,
  // 'user/check'

export default mockMap;
