import parners1 from '../../../static/aboutPage/Partner1.png';
import parners2 from '../../../static/aboutPage/Partner2.png';
import parners3 from '../../../static/aboutPage/Partner3.png';
import parners4 from '../../../static/aboutPage/Partner4.png';
import parners5 from '../../../static/aboutPage/Partner5.png';
import parners6 from '../../../static/aboutPage/Partner6.png';
import holowImagePartners from '../../../static/aboutPage/defaultPartner.png';

export const mockDataParners = [
  {
    id: 1,
    icon: parners1,
  },
  {
    id: 2,
    icon: parners2,
  },
  {
    id: 3,
    icon: parners3,
  },
  {
    id: 4,
    icon: holowImagePartners,
  },
  {
    id: 5,
    icon: holowImagePartners,
  },
  {
    id: 6,
    icon: parners4,
  },
  {
    id: 7,
    icon: parners5,
  },
  {
    id: 8,
    icon: parners6,
  },
];
