import React from 'react';
import {Link} from 'react-router-dom';
import Slogan from '../../../components/slogan';
import RootPage from '../../rootPage';
import backArrow from '../../../static/icons/main/left-arrow.svg';
import DragAndDrop from '../../../components/Drag\'n\'Drop/DragAndDrop';
import clip from '../../../static/productpage/clip.svg';
import buttonOk from '../../../static/productpage/btn-submit.svg';
import edit from '../../../static/productpage/edit.svg';

const AddFileProduct: React.FC = () => {
  const [docs, setDocs] = React.useState<string[]>([]);

  const nameColums = [
    {id: 1, name: 'Категория'},
    {name: 'Название', id: 2},
    {name: 'Цена', id: 3},
    {name: 'Кол-во', id: 4},
    {name: 'Доставка', id: 5},
    {name: 'Оплата', id: 6},
    {name: 'link', id: 7},
  ];
  const mokDataFile = [
    {
      id: 1,
      category: 'Категория',
      name: 'Name',
      price: 'Price',
      count: 'count',
      develiry: 'develiry',
      payment: 'payment',
      link: 'link',
    },
    {
      id: 2,
      category: 'Категория1',
      name: 'Name1',
      price: 'Price1',
      count: 'count',
      develiry: 'develiry',
      payment: 'payment',
      link: 'link',
    },
  ];

  return (
    <RootPage nameOfActive="File">
      <Slogan />
      <main
        className="max-w-[640px] mx-auto p-[40px] mt-[40px] lg:max-w-[1232px] md:max-w-[940px] mb-[100px]"
        style={{
          borderRadius: '12px',
          background: '#FFF',
          boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.07)',
        }}
      >
        <form className="flex flex-col justify-start gap-[20px]">
          <Link to="/product/add" className="flex flex-row gap-3">
            <img src={backArrow} alt="back" className="w-[14px] h-[100%]" />
            <h6 className="text-[14px] mt-0 mb-0">Назад</h6>
          </Link>
          <h4>Добавить товары фаилом</h4>
          <div className="w-[544px] h-[262px]">
            <DragAndDrop
              state={[docs, setDocs]}
              innerImage={clip}
              innerText="Загрузите фаил или перетяните его в поле"
              label="Прикрепление товара"
              type="file"
            />
          </div>
          {docs.length > 0 && (
            <>
              <h4> Редактирование товаров</h4>
              <div className="flex flex-col">
                <div className="flex justify-between items-center bg-[#3E74DD] rounded-t-[8px]">
                  {nameColums.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="flex items-center justify-center  h-[70px] w-[100%]"
                      >
                        <h6 className="text-[16px] text-[white] p-[20px]">
                          {item.name}
                        </h6>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{border: '1px solid #1D365324'}}
                  className="flex flex-col rounded-b-[8px] bg-[#FAFCFF]"
                >
                  {mokDataFile.map((item) => {
                    return (
                      <li
                        key={item.id}
                        style={{borderBottom: '1px solid #1D365324'}}
                        className="flex justify-between items-center h-[70px]"
                      >
                        <div
                          style={{borderRight: '1px solid #1D365324'}}
                          className="flex items-center justify-center w-[100%] h-[100%]"
                        >
                          <h6>{item.category}</h6>
                        </div>
                        <div
                          style={{borderRight: '1px solid #1D365324'}}
                          className="flex items-center justify-center w-[100%] h-[100%]"
                        >
                          <h6>{item.name}</h6>
                        </div>
                        <div
                          style={{borderRight: '1px solid #1D365324'}}
                          className="flex items-center justify-center w-[100%] h-[100%]"
                        >
                          <h6>{item.price}</h6>
                        </div>
                        <div
                          style={{borderRight: '1px solid #1D365324'}}
                          className="flex items-center justify-center w-[100%] h-[100%]"
                        >
                          <h6>{item.count}</h6>
                        </div>
                        <div
                          style={{borderRight: '1px solid #1D365324'}}
                          className="flex items-center justify-center w-[100%] h-[100%]"
                        >
                          <h6>{item.develiry}</h6>
                        </div>
                        <div
                          style={{borderRight: '1px solid #1D365324'}}
                          className="flex items-center justify-center w-[100%] h-[100%]"
                        >
                          <h6>{item.payment}</h6>
                        </div>
                        <Link
                          to={'/product/add'}
                          className="flex items-center justify-center w-[100%] h-[100%]"
                        >
                          <img
                            src={edit}
                            className="h-[18px] w-[18px]"
                            alt="edit"
                          />
                        </Link>
                      </li>
                    );
                  })}
                </div>
              </div>
              <button
                className="w-[248px] h-[41px] bg-[#61B43A] flex justify-center
          items-center rounded-[8px] gap-[18px] hover:opacity-50"
              >
                <img src={buttonOk} className="w-[18px] h-[18px]" alt="ok" />
                <h6 className="text-[14px] text-[#FFFFFF]">
                  {' '}
                  Разместить объявления
                </h6>
              </button>
            </>
          )}
        </form>
      </main>
    </RootPage>
  );
};

export default AddFileProduct;
