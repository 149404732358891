import React, {FC} from 'react';
import type {SuggestionsInputRendrableOption} from './suggestionsInputComponent';
import './react-dadata.css';
import {HighlightWords} from '../../libs/components/dadata/HighlightWords';
import {SearchCategoriesResponseI} from '../../api/messageTypes';

const wordsToPass = ['г', 'респ', 'ул', 'р-н', 'село', 'деревня', 'поселок', 'пр-д', 'пл', 'к', 'кв', 'обл', 'д'];

const getHighlightWords = (inputQuery: string): string[] => {
  let words = inputQuery.replace(',', '').split(' ');
  // исключаем выделение шаблонных слов wordsToPass
  words = words.filter((word) => {
    return wordsToPass.indexOf(word) < 0;
  });
  return words;
};

const CompanyCategoryRenderOptionComponent: FC<SuggestionsInputRendrableOption<SearchCategoriesResponseI>> =
({suggestion, inputQuery}) => {
  return (
    <HighlightWords
      highlightClassName='new-react-dadata--highlighted'
      words={getHighlightWords(inputQuery)}
      text={suggestion.name}
    />
  );
};

export {CompanyCategoryRenderOptionComponent};
export default CompanyCategoryRenderOptionComponent;
