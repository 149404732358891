import React from 'react';
import {Avatar, Box, IconButton, Stack, Typography, Link} from '@mui/material';
import s from './footer.module.scss';
import sponsors from '../../static/landing/sponsors.svg';
import logo from '../../static/landing/logo.svg';
import call from '../../static/landing/call.svg';
import receive from '../../static/landing/directboxreceive.svg';
import {useNavigate} from 'react-router';

const Footer: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box component="footer" className={s.container}>
      <Box className={s.upper}>
        <Box className={s.upper__left}>
          <Box sx={{width: '320px', height: '60px', display: 'flex'}}>
            <IconButton onClick={() => navigate('/')}>
              <Avatar
                src={logo}
                alt="Logo"
                sx={{width: '60px', height: '60px'}}
              />
            </IconButton>
            <Stack
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginLeft: '15px',
              }}
            >
              <Typography fontSize={22}>битуби</Typography>
              <Typography fontSize={10} color={'grey'}>
                Ищи БЫСТРО и пиши НАПРЯМУЮ поставщикам строительных материалов
              </Typography>
            </Stack>
          </Box>
          <Box className={s.contacts}>
            <Box>
              <Avatar src={call} sx={{width: '20px', height: '20px'}} />
              <Typography>
                <a
                  style={{color: '#707895'}}
                  href="mailto:partners@bi-tu-bi.ru"
                >
                  partners@bi-tu-bi.ru
                </a>
              </Typography>
            </Box>
            <Box>
              <Avatar src={receive} sx={{width: '20px', height: '20px'}} />
              <Typography>
                <a style={{color: '#707895'}} href="tel:89262828813">
                  +7 (926) 282-88-13‬
                </a>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={s.upper__right}>
          <img className="footer__sponsors-img" src={sponsors} alt="" />
        </Box>
      </Box>

      <Box className={s.lower}>
        <Box className={s.lower__left}>
          <Typography style={{fontSize: '10px'}}>
            <Link
              href="https://docs.google.com/document/d/10-kqv4U8eTChwkWuIkgMD2UMsK7EEBhyUgHZpz5qu8Y/edit"
              color="inherit"
            >
              Политика обработки персональных данных
            </Link>
          </Typography>
          <Typography style={{fontSize: '10px'}}>
            <Link
              href="https://docs.google.com/document/d/1p8A_xutZG5AK6USW35AFAkonuSGMjaYeb-IEJ6Wc3do/edit"
              color="inherit"
            >
              Публичная оферта
            </Link>
          </Typography>
          <Typography style={{fontSize: '10px'}}>
            <Link
              href="https://docs.google.com/document/d/1MUhf1prNAzaSCjbvq2Byk7kQSJrY86vJDFu47wIuIFc/edit"
              color="inherit"
            >
              Условия использования
            </Link>
          </Typography>
        </Box>
        <Typography className={s.lower__right} style={{fontSize: '10px'}}>
          Занимайтесь стройкой, а лучших поставщиков мы найдём за вас!
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
