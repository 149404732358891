import React from 'react';
import RootPage from '../rootPage';
import style from './style.module.scss';
import arrowLeft from '../../static/catalogPage/arrowLeft.svg';
import {Button, Chip, Divider, IconButton, Menu, MenuItem} from '@mui/material';
import trash from '../../static/catalogPage/trash.svg';
import searchLoop from '../../static/catalogPage/searchLoop.svg';
import sort from '../../static/catalogPage/sort.svg';
import CardMobile from './CardMobile';
import {GoodT} from '../../redux/reducers/categoriesReducer';
import menu from '../../static/catalogPage/Menu.svg';
import {useNavigate} from 'react-router';
import {APIurl} from '../../utils/globals';

interface Props {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  searchProcucts: () => Promise<void>;
  inputHandler: (e: React.FormEvent) => void;
  handleKeyDown: (e: any) => void;
  handleButtonClickSearch: (e: any) => void;
  goods: GoodT[];
  currentUser: any;
}

export default function CatalogPageMobile({
  tags,
  setTags,
  searchProcucts,
  inputHandler,
  handleKeyDown,
  handleButtonClickSearch,
  goods,
  currentUser,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const [isAuthed, setIsAuthed] = React.useState(false);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorEl(null);
  };

  const handleGoLink = (url: string) => {
    navigate(url);
  };
  React.useEffect(() => {
    fetch(`${APIurl}/user/me`, {credentials: 'include'}).then((res) =>
      res.status === 401 ? setIsAuthed(false) : setIsAuthed(true),
    );
  }, []);

  return (
    <RootPage nameOfActive="CategoryPage">
      <main className={style.catalog}>
        <div className={style.catalog__header}>
          <img
            src={arrowLeft}
            alt="back"
            className={style.catalog__iconArrow}
          />

          <div className={style.catalog__searchWrapper}>
            <input
              onInput={(e) => inputHandler(e)}
              id="search-input"
              onKeyDown={handleKeyDown}
              type="text"
              placeholder="поиск на битуби"
            />
            <IconButton onClick={handleButtonClickSearch}>
              <img
                src={searchLoop}
                alt="search"
                className={style.catalog__iconSearch}
              />
            </IconButton>
          </div>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
          >
            <img src={menu} alt="menu" className={style.catalog__iconArrow} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorEl)}
            onClose={handleCloseNavMenu}
            sx={{
              display: {xs: 'block', md: 'none'},
            }}
          >
            <MenuItem onClick={() => handleGoLink('/categories')}>
              Товары
            </MenuItem>
            <MenuItem onClick={() => handleGoLink('/about')}>О нас</MenuItem>
            <MenuItem onClick={() => handleGoLink('/product/add')}>
              Купить/Продать
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => handleGoLink('https://t.me/Sopha_m1')}>
              Менеджер
            </MenuItem>
            <MenuItem onClick={() => handleGoLink('/chat')}>Чат</MenuItem>
            <Divider />
            <MenuItem
              onClick={() =>
                isAuthed ? handleGoLink('/profile') : handleGoLink('/register')
              }
            >
              {isAuthed ? 'Личный кабинет' : 'Войти'}
            </MenuItem>
          </Menu>
        </div>
        <div className={style.catalog__filters}>
          <div className={style.catalog__filtersContent}>
            <div className={style.catalog__title}>Кирпичи и блоки</div>
            <Button
              sx={{color: '#272121', textTransform: 'none'}}
              className={style.catalog__buttonFilters}
            >
              Фильтры
            </Button>
          </div>
          <div className={style.catalog__chipsList}>
            {tags.map((item, index) => {
              return (
                <Chip
                  label={item}
                  key={index}
                  variant="outlined"
                  onDelete={() => {
                    const updatedTags = tags.filter((tag) => tag !== item);
                    setTags(updatedTags);
                    searchProcucts();
                  }}
                />
              );
            })}
          </div>
          <div>
            <Button
              startIcon={<img src={sort} alt="sort" />}
              sx={{color: '#272121', textTransform: 'none', padding: '0'}}
              className={style.catalog__buttonSort}
            >
              Сортировки
            </Button>
          </div>
        </div>

        <section className={style.catalog__cardList}>
          {goods.map((item) => {
            return (
              <CardMobile key={item.id} card={item} currentUser={currentUser} />
            );
          })}
        </section>
      </main>
    </RootPage>
  );
}
