export type OptionsStatusType = {
  id: number;
  text: string;
  colorBackground: string;
};

export const optionsStatus: OptionsStatusType[] = [
  {
    id: 1,
    text: 'Доставлено',
    colorBackground: `#61B43A`,
  },
  {
    id: 2,
    text: 'Оплачено',
    colorBackground: `#375BA1`,
  },
  {
    id: 3,
    text: 'Выставлен счет',
    colorBackground: `#F99417`,
  },
  {
    id: 4,
    text: 'Обсуждение',
    colorBackground: `#6C9CF8`,
  },
  {
    id: 5,
    text: 'Отказано',
    colorBackground: `#DF2E38`,
  },
];
