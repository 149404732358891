import {createSlice} from '@reduxjs/toolkit';

type userStateType = {
  data: {
    id: number;
    name: string;
    surname: string;
    avatar: string;
    email: string;
    description: string;
    company: {
      id: number;
      name: string;
      description: string;
      legal_name: string;
      itn: string;
      psrn: string;
      address: string;
      legal_address: string;
      email: string;
      phone: string;
      link: string;
      activity: string;
      owner_id: number;
      rating: number;
      verified: number;
    };
    companyUser: {
      post: string;
      company_id: number;
      user_id: number;
      itn: string;
    };
  };

  status: 'init' | 'loading' | 'success' | 'error';
  error: string | undefined;
};

const initialState: userStateType = {
  data: {
    id: -1,
    name: '',
    surname: '',
    avatar: '',
    email: '',
    description: '',
    company: {
      id: -1,
      name: '',
      description: '',
      legal_name: '',
      itn: '',
      psrn: '',
      address: '',
      legal_address: '',
      email: '',
      phone: '',
      link: '',
      activity: '',
      owner_id: -1,
      rating: -1,
      verified: -1,
    },
    companyUser: {
      post: '',
      company_id: -1,
      user_id: -1,
      itn: '',
    },
  },
  status: 'init',
  error: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserMe: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const {reducer: userReducer, actions: userActions} = userSlice;
export const {getUserMe} = userSlice.actions;
