import penoblock from '../../../static/imgs/penblock.svg';
import defaultImageStory from '../../../static/imgs/storeDefault.png';
import imgStory1 from '../../../static/storysImg/story1.jpg';
import imgStory2 from '../../../static/storysImg/story2.jpg';
import imgStory3 from '../../../static/storysImg/story3.jpg';
import imgStory4 from '../../../static/storysImg/story4.jpg';
import imgStory5 from '../../../static/storysImg/story5.jpg';

import saunaPic from '../../../static/categoriesPics/1.png';
import doorsPic from '../../../static/categoriesPics/2.png';
import insulationPic from '../../../static/categoriesPics/3.png';
import instrumentPic from '../../../static/categoriesPics/4.png';
import roofPic from '../../../static/categoriesPics/5.png';
import finishingPic from '../../../static/categoriesPics/7.png';
import lumberPic from '../../../static/categoriesPics/8.png';
import buildingPic from '../../../static/categoriesPics/10.png';
import electricalPic from '../../../static/categoriesPics/12.png';
import companyPic from '../../../static/categoriesPics/companies_img.jpg';

import saunaPicCompressed from '../../../static/categoriesPics/compressed/1.png';
import doorsPicCompressed from '../../../static/categoriesPics/compressed/2.png';
import insulationPicCompressed from '../../../static/categoriesPics/compressed/3.png';
import instrumentPicCompressed from '../../../static/categoriesPics/compressed/4.png';
import roofPicCompressed from '../../../static/categoriesPics/compressed/5.png';
import finishingPicCompressed from '../../../static/categoriesPics/compressed/7.png';
import lumberPicCompressed from '../../../static/categoriesPics/compressed/8.png';
import buildingPicCompressed from '../../../static/categoriesPics/compressed/10.png';
import electricalPicCompressed from '../../../static/categoriesPics/compressed/12.png';
import companyPicCompressed from '../../../static/categoriesPics/compressed/companies_img.jpg';

export const dataMok = [
  {
    id: 1,
    title: 'Баня и отопление',
    image: saunaPic,
    link: '/categories/sauna-and-heating',
  },
  {
    id: 2,
    title: 'Изоляционные материалы',
    image: insulationPic,
    link: '/categories/insulation-materials',
  },
  {
    id: 3,
    title: 'Инструменты',
    image: instrumentPic,
    link: '/categories/instrument',
  },
  {
    id: 4,
    title: 'Двери. Окна. Лестницы',
    image: doorsPic,
    link: '/categories/doors-windows-staircases',
  },
  {
    id: 5,
    title: 'Кровля. Фасад. Забор.',
    image: roofPic,
    link: '/categories/roof-facade-fence',
  },
  {
    id: 6,
    title: 'Отделочные материалы',
    image: finishingPic,
    link: '/finishing-materials',
  },
  {
    id: 7,
    title: 'Пиломатериалы',
    image: lumberPic,
    link: '/categories/lumber',
  },
  {
    id: 8,
    title: 'Строительные материалы',
    image: buildingPic,
    link: '/categories/building-materials',
  },
  {
    id: 9,
    title: 'Компании',
    image: companyPic,
    link: '/categories/сompanies',
  },
];


export const dataMokCompressed = [
  {
    id: 1,
    title: 'Баня и отопление',
    image: saunaPicCompressed,
    link: '/categories/sauna-and-heating',
  },
  {
    id: 2,
    title: 'Изоляционные материалы',
    image: insulationPicCompressed,
    link: '/categories/insulation-materials',
  },
  {
    id: 3,
    title: 'Инструменты',
    image: instrumentPicCompressed,
    link: '/categories/instrument',
  },
  {
    id: 4,
    title: 'Двери. Окна. Лестницы',
    image: doorsPicCompressed,
    link: '/categories/doors-windows-staircases',
  },
  {
    id: 5,
    title: 'Кровля. Фасад. Забор.',
    image: roofPicCompressed,
    link: '/categories/roof-facade-fence',
  },
  {
    id: 6,
    title: 'Отделочные материалы',
    image: finishingPicCompressed,
    link: '/categories/finishing-materials',
  },
  {
    id: 7,
    title: 'Пиломатериалы',
    image: lumberPicCompressed,
    link: '/categories/lumber',
  },
  {
    id: 8,
    title: 'Строительные материалы',
    image: buildingPicCompressed,
    link: '/categories/building-materials',
  },
  {
    id: 9,
    title: 'Компании',
    image: companyPicCompressed,
    link: '/categories/сompanies',
  },
];

export const dataMokStory = [
  {
    id: 1,
    title: 'В России предложили',
    image: imgStory1,
    link: 'https://realty.ria.ru/20231228/markirovka-1918751959.html',
  },
  {
    id: 2,
    title: 'Экспорт пиломатериалов из России сократится на 7% по итогам года',
    image: imgStory2,
    link: 'https://realty.ria.ru/20231221/pilomaterialy-1917216360.html',
  },
  {
    id: 3,
    title: 'ВЦИОМ: застройщики Москвы жалуются на подорожание стройматериалов',
    image: imgStory3,
    link: 'https://realty.ria.ru/20231213/stroymaterialy-1915502341.html',
  },
  {
    id: 4,
    title: 'ФАС заявила о снижении цен на арматуру в России',
    image: imgStory4,
    link: 'https://realty.ria.ru/20231208/armatura-1914529937.html',
  },
  {
    id: 5,
    title: 'ФАС видит падение цен на арматуру в России',
    image: imgStory5,
    link: 'https://realty.ria.ru/20231107/armatura-1907844469.html',
  },
  {
    id: 6,
    title: 'Стоимость строительных материалов: последние новости на сегодня',
    image: imgStory2,
    link: ' https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjR6vSegbWDAxWvjLAFHX3XDagQFnoECA4QAQ&url=https%3A%2F%2Flenta.ru%2Ftags%2Fstory%2Fstroiprice%2F&usg=AOvVaw1DpxDGirx7LzHObyFay6P3&opi=89978449',
  },
];

export type typeStory = {
  id: number;
  title: string;
  image: string;
  link: string;
};
