import {Box, Button, Container, Typography} from '@mui/material';
import React from 'react';
import s from './authFiled.module.scss';
import {useNavigate} from 'react-router';
import {useMediaQuery} from '@mui/material';

const AuthField: React.FC = () => {
  const navigate = useNavigate();
  const matchesMobile = useMediaQuery('(max-width: 700px)');

  return (
    <Box component="section" className={matchesMobile ? s.container_without_img : s.container}>
      <Box className={s.inner_container}>
        <Typography className='authFiled'>
          Регистрируйтесь, чтобы получать отклики поставщиков быстрее
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'white',
          }}
          style={!matchesMobile ? {
            color: 'black',
            height: '61px',
            width: '364px',
            fontSize: '20px',
            marginTop: '35px',
            borderRadius: '12px',
          } : {textTransform: 'none', fontSize: 10, color: 'black', width: '260px', marginTop: 18}
          }
          onClick={() => navigate('/register')}
        >
          Создать аккаунт или войти
        </Button>
      </Box>
    </Box>
  );
};

export default AuthField;
