/* eslint-disable */
import React, {FC, useEffect, useState, ChangeEvent} from 'react';

import CartReducer, {
  CartActions,
  items,
} from '../../redux/reducers/cartReducer';

import DefaultImg from '../../images/default.png';
import HeartIcon from '../../images/heart.svg';
import TrashIcon from '../../images/trash.svg';
import './cartItem.scss';

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars*/

interface CartItemPropsI {
  id: string;
  name: string;
  companyName: string;
  price: number;
  checkedAll: boolean;
}

const CartItem: FC<CartItemPropsI> = ({
  id,
  name,
  companyName,
  price,
  checkedAll,
}) => {
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [checked, setChecked] = useState(false);
  const [removed, remove] = useState(false);

  const validateNumberOfItems = (n: number): number => {
    if (n < 0) {
      setNumberOfItems(0);
      return 0;
    }
    return n;
  };

  useEffect(() => {
    setChecked(checkedAll);
  }, [checkedAll]);

  return !removed ? (
    <div id={id} className="cart__item">
      <label className="cart__item-checkbox" htmlFor={id + '-input'}>
        <input
          type="checkbox"
          checked={checked}
          onChange={(e: ChangeEvent) =>
            setChecked((e.target as HTMLInputElement)?.checked)
          }
          className="checkbox_large cart__item-checkbox"
          id={id + '-input'}
        />
      </label>
      <div className="cart__item-img">
        <img src={DefaultImg} alt="default_image" />
      </div>
      <div className="cart__item-name">
        <h2 className="cart__item-title">{name}</h2>
        <h5 className="cart__item-company">{companyName}</h5>
      </div>
      <div className="cart__item-pricing">
        <span className="cart__item-price">{price}₽</span>
        <div className="cart__counter">
          <button
            onClick={() => setNumberOfItems((prevNum) => prevNum - 1)}
            className="btn theme-blue cart__counter-btn"
          >
            -
          </button>
          {validateNumberOfItems(numberOfItems)}
          <button
            onClick={() => setNumberOfItems((prevNum) => prevNum + 1)}
            className="btn theme-blue cart__counter-btn"
          >
            +
          </button>
        </div>
      </div>
      <div className="cart__item-icons">
        <a href="/" className="cart__item-link">
          <img src={HeartIcon} alt="" className="cart__item-icon" />
        </a>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            remove(true);
          }}
          className="cart__item-link"
        >
          <img src={TrashIcon} alt="delete" className="cart__item-icon" />
        </a>
      </div>
    </div>
  ) : null;
};

export {CartItem};
export default CartItem;
