import React, {FormEventHandler, useEffect, useState} from 'react';
import ValidatableInput from '../../../../components/validatableInput/validatableInputComponent';
import regexpValidation, {itnSymbols} from '../../../../utils/regexp';
import Ajax from '../../../../api/ajax';
import {useNavigate} from 'react-router';
import {ajaxDebug} from '../../../../utils/globals';
import {HttpCache} from '../http-cache/abstract';
import {PartySuggestions} from '../PartySuggestions';
import {BaseState, DaDataParty, DaDataSuggestion} from '../types';
import IItnComponent from '../../../../pages/landing/types/IItnComponent';
import {Box} from '@mui/material';

const ItnLandingForm: React.FC<IItnComponent> = (props) => {
  const {manageITNState, manageCompanyState} = props;

  const [isCompanyNameValid, setIsCompanyNameValid] = useState(false);
  const [isCompanyLegalNameValid, setIsCompanyLegalNameValid] = useState(false);
  // const [isItnValid, setIsItnValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const [previousErrorMsg, setPreviousErrorMsg] = useState('');
  const [dadataState, setDadataState] = useState<BaseState<DaDataParty>>({
    query: '',
    inputQuery: '',
    isFocused: false,
    displaySuggestions: true,
    suggestions: [],
    suggestionIndex: -1,
  });
  const genManageCompanyFieldState = (
    field: keyof (typeof manageCompanyState)[0],
  ) =>
    [
      manageCompanyState[0][field],
      (value: typeof field) =>
        manageCompanyState[1]({...manageCompanyState[0], [field]: value}),
    ] as [string, (s: string) => void];
  const manageCompanyBrandNameState = genManageCompanyFieldState('brandName');
  const manageCompanyLegalNameState = genManageCompanyFieldState('legalName');
  const isItnValid =
    (dadataState.query.length === 10 || dadataState.query.length === 12) &&
    itnSymbols.test(dadataState.query);

  const selectedSuggestion = dadataState.suggestions.filter(
    ({data: {inn}}) => inn === dadataState.query,
  )[0];
  // const selectedSuggestion = dadataState.suggestions[dadataState.suggestionIndex];

  useEffect(() => {
    if (
      isItnValid &&
      dadataState.query !== manageITNState[0] &&
      dadataState.suggestions.length !== 0
    ) {
      if (selectedSuggestion) {
        manageCompanyState[1]({
          legalName: selectedSuggestion.value,
          brandName: selectedSuggestion.data.name.short,
          address: selectedSuggestion.data.address.value,
          opf: selectedSuggestion.data.opf.short,
        });
      } else {
        manageCompanyState[1]({
          legalName: '',
          brandName: '',
          address: '',
          opf: '',
        });
      }

      manageITNState[1](dadataState.query);
    }
  }, [
    selectedSuggestion,
    dadataState,
    manageCompanyState,
    isItnValid,
    manageITNState,
  ]);

  const navigate = useNavigate();

  const isValidItn =
    isCompanyNameValid &&
    isCompanyLegalNameValid &&
    isItnValid &&
    !!dadataState.query &&
    !!manageCompanyState[0].legalName &&
    !!manageCompanyState[0].brandName &&
    !!manageCompanyState[0].opf;

  let errorMsg = 'Нарушен формат полей';
  if (!dadataState) {
    errorMsg = 'Пропущено поле ИНН';
  }

  if (showError && errorMsg !== previousErrorMsg) {
    setShowError(false);
  }

  return (
    <>
      <PartySuggestions
        httpCache={true}
        substituteOnClick={(suggestion: any) => suggestion.data.inn}
        delay={200}
        inputProps={{placeholder: 'ИНН'}}
        manageState={[dadataState, setDadataState]}
        createRequestPromise={function <DaDataParty>(
          query: string,
          cache: HttpCache | null,
        ) {
          if (!query) {
            if (ajaxDebug) {
              console.log(
                '[Register Form => DaDataParty] не отправляю пустое поле',
              );
            }
            return (async () => [])();
          }

          let cacheKey: string;
          if (cache) {
            cacheKey = cache.serializeCacheKey({
              url: query,
              requestParams: {},
            });
            const cachedData = cache.get(
              cacheKey,
            ) as unknown as DaDataSuggestion<DaDataParty>[];
            if (cachedData) {
              if (ajaxDebug) {
                console.log(
                  '[Register Form => DaDataParty] из кеша:',
                  cachedData,
                );
              }
              return (async () => cachedData)();
            }
          }

          return Ajax.post({
            url: `/company/itn/${query}`,
            body: {},
          })
            .then(({status, response}) => {
              const retVal =
                (status === Ajax.STATUS.ok &&
                  (response.data as DaDataSuggestion<DaDataParty>[])) ||
                [];
              cache?.set(cacheKey, retVal);
              return retVal;
            })
            .catch((err) => {
              console.warn(err);
              return [];
            });
        }}
      />

      {/* &__company {
      opacity: 0;
      transition: opacity .5s ease-in-out;

      &.active {
        opacity: 1;
      } */}
      <span className={'register__company' + (isItnValid ? ' active' : '')}>
        {selectedSuggestion ? (
          <p className="text-secondary">Проверьте, все ли верно</p>
        ) : (
          <p className="text-secondary">
            Компания не найдена в нашем реестре.
            <br />
            Проверьте правильность ввода ИНН
            <br />
            или заполните данные вручную
          </p>
        )}
        <div className="row" />
        <div className="row">
          <ValidatableInput
            className="col s12"
            id="CompanyLegalName"
            labelSign="Полное название"
            required={true}
            trimmed={false}
            tooltipWidth="200px"
            conditions={regexpValidation.companyFullName.conditions}
            valueState={manageCompanyLegalNameState}
            validState={[isCompanyLegalNameValid, setIsCompanyLegalNameValid]}
          />
        </div>
        <div className="row">
          <ValidatableInput
            className="col s12"
            id="CompanyBrandName"
            labelSign="Название бренда"
            required={true}
            trimmed={false}
            tooltipWidth="200px"
            conditions={regexpValidation.companyName.conditions}
            valueState={manageCompanyBrandNameState}
            validState={[isCompanyNameValid, setIsCompanyNameValid]}
          />
        </div>
      </span>

      {!isValidItn ? <Box>{showError ? errorMsg : ''}</Box> : ''}
    </>
  );
};

export default ItnLandingForm;
