/* eslint-disable */
import React, {useEffect} from 'react';
import TextInput from '../../../components/NewInputs/TextInput';
import Header from '../../../components/header/headerComponent';
import Slogan from '../../../components/slogan';
import CheckboxGroup from '../../../components/NewInputs/CheckboxGroup';
import tickCircle from '../../../static/productpage/galochka.svg';
import SideWindow from '../../../components/SideWindow/SideWindow';
import DragAndDrop from "../../../components/Drag'n'Drop/DragAndDrop";
import camera from '../../../static/productpage/camera.svg';
import plus from '../../../static/productpage/dndPlus.png';
import Dropdown from '../../../components/NewInputs/Dropdown';
import {APIurl} from '../../../utils/globals';
import {useMediaQuery} from '@mui/material';
import {Link, useNavigate, useParams} from 'react-router-dom';
import CustomSnackbar from '../../../components/Snackbar/Snackbar';

const paywayOptions = [
  'Наличными',
  'Постоплата',
  'Переводом на счет',
  'Банковской картой',
];
const deliveryWayOptions = [
  'Самовывоз',
  'Автопарком компании',
  'Транспортной компанией',
  'Почтой или курьером',
];

const AddProduct: React.FC = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [categoryId, setCategoryId] = React.useState<number>();
  const [good, setGood] = React.useState('');
  const [info, setInfo] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [quantity, setQuantity] = React.useState('');
  const [payWay, setPayWay] = React.useState<string[]>([]);
  const [adress, setAdress] = React.useState('');
  const [deliveryWay, setDeliveryWay] = React.useState<string[]>([]);
  const [goodPhoto, setGoodPhoto] = React.useState<string[]>([]);
  const [docs, setDocs] = React.useState<string[]>([]);
  const [userData, setUserData] = React.useState<any>();

  const [openSnackbarSuccess, setOpenSnackbarSuccess] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);

  const fetchMe = async () => {
    try {
      const response = await fetch(`${APIurl}/user/me`, {
        credentials: 'include',
        method: 'GET',
      });
      if (response.status === 200) {
        const data = await response.json();
        setUserData(data);
      } else if (response.status === 401) {
        setUserData('Не авторизован');
      } else {
        console.error('Error:', response.status);
      }
    } catch (e) {
      console.warn(e);
    }
  };
  const fetchProductById = async () => {
    try {
      const response = await fetch(`${APIurl}/product/${id}`, {
        credentials: 'include',
        method: 'GET',
      });
      if (response.status === 200) {
        const {data} = await response.json();
        setGood(data.name);
        setInfo(data.info);
        setPrice(data.price);
        setDocs(data.docs), setCategoryId(data.category_id);
        setAdress(data.adress);
        setGoodPhoto(data.photo);
      } else if (response.status === 401) {
        console.log('не авторизован');
      } else {
        console.error('Error:', response.status);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    fetchMe();
    if (id) {
      fetchProductById();
    }
  }, []);

  const name =
    userData === 'Не авторизован' ? '' : userData?.data.company.legal_name;

  const addProductOptions = [
    {option: 'Информация об организации', link: '/profile'},
    {option: 'Добавить объявление', link: '/product/add'},
    {option: 'Чат с заказчиком', link: '/chat'},
    {option: 'Мои товары', link: `/company/${userData?.data?.company?.id}`},
  ];

  const mathches = useMediaQuery('(max-width: 700px)');

  const handlePaywayChange = (updatedSelectedValues: any) => {
    setPayWay(updatedSelectedValues);
  };
  const handleDeliverywayChange = (updatedSelectedValues: any) => {
    setDeliveryWay(updatedSelectedValues);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const requestBody = JSON.stringify({
      name: good,
      info: info,
      price: Number(price),
      docs: docs,
      category_id: categoryId,
      amount: Number(quantity),
      payWay: payWay.join('/'),
      deliveryWay: deliveryWay.join('/'),
      adress: adress,
      product_photo: goodPhoto,
    });

    fetch(`${APIurl}/product/add`, {
      method: 'POST',
      body: requestBody,
      credentials: 'include',
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setOpenSnackbarSuccess(true);
          setTimeout(() => {
            navigate('../../');
            window.scrollTo(0, 0);
          }, 4000);
        } else {
          setOpenSnackbarError(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header />
      <Slogan />
      <form
        className="!mt-[45px] container flex gap-[16px]"
        action="POST"
        onSubmit={handleSubmit}
      >
        {mathches ? null : (
          <SideWindow
            nameOfActive={'Добавить объявление'}
            legalName={name}
            linkOptions={addProductOptions}
          />
        )}
        <main
          className="max-w-[920px] p-[40px]"
          style={{
            borderRadius: '12px',
            background: '#FFF',
            boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.07)',
          }}
        >
          <h4 className="mb-[25px]">Добавить объявление</h4>
          <TextInput
            label="Название"
            placeholder="Добавьте название объявления"
            state={[good, setGood]}
          />
          <Dropdown
            label="Категории"
            placeholder="Напишите категорию и выберите из списка"
            categoryIdState={[categoryId, setCategoryId]}
          />
          <h4>Внешний вид</h4>
          <DragAndDrop
            state={[goodPhoto, setGoodPhoto]}
            innerImage={camera}
            innerText="Загрузите фотографии или перетяните их в поле"
            label="Фотографии товара"
            type="photo"
          />
          <h4>Дополнительная информация</h4>
          <TextInput
            placeholder={
              'Расскажите о товарах: добавьте описание и характеристики'
            }
            state={[info, setInfo]}
            textarea
            className="mb-[20px]"
            label="Описание"
          />
          <div className="flex justify-between  my-[20px]">
            <TextInput
              label="Цена"
              placeholder="Введите цену"
              state={[price, setPrice]}
              className="w-[200px]"
            />
            <TextInput
              label="Количество"
              placeholder="Введите количество"
              className="w-[200px]"
              state={[quantity, setQuantity]}
            />
          </div>
          <CheckboxGroup
            options={paywayOptions}
            selectedValues={payWay}
            onChange={handlePaywayChange}
            label="Способ оплаты"
            className="flex-col mb-[30px]"
          />
          <CheckboxGroup
            options={deliveryWayOptions}
            selectedValues={deliveryWay}
            onChange={handleDeliverywayChange}
            label="Способ доставки"
            className="flex-col"
          />
          <TextInput
            label="Адрес расположения товаров "
            placeholder="Введите адрес, а затем выберите из списка"
            state={[adress, setAdress]}
          />
          <h4>Документация</h4>
          <DragAndDrop
            state={[docs, setDocs]}
            innerImage={plus}
            innerText="Загрузите дополнительные документы или перетяните их в поле"
            label="Сопроводительные документы"
            type="docs"
          />
          <div className="flex row justify-end">
            <button
              className="flex items-center px-[14px] py-[11px] gap-7 mt-8"
              style={{
                borderRadius: '8px',
                opacity: 0.9,
                background: '#61B43A',
                color: '#FFF',
                fontSize: '14px',
                fontWeight: '500',
              }}
              type="submit"
            >
              <img src={tickCircle} alt="pic" />
              {/* <p style={{color: '#FFF', fontSize: '14px', fontWeight: '500'}}> */}
              Разместить объявление
              {/* </p> */}
            </button>
          </div>
        </main>
      </form>

      <CustomSnackbar
        open={openSnackbarSuccess}
        setOpen={setOpenSnackbarSuccess}
        variant="success"
      />
      <CustomSnackbar
        open={openSnackbarError}
        setOpen={setOpenSnackbarError}
        variant="error"
      />
    </>
  );
};

export default AddProduct;
