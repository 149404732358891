import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Typography,
  Link,
  useMediaQuery,
} from '@mui/material';
import logo from '../../static/landing/logo.svg';
import call from '../../static/landing/call.svg';
import receive from '../../static/landing/directboxreceive.svg';
// import companies from '../../static/landing/companiemobile.png';
import companies from '../../static/landing/Frame 529.svg';
import s from './footermobile.module.scss';
import FormsModal from '../Modals/FormsModal';

const FooterMobile: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleIconClose = () => {
    setOpen(false);
  };

  const matches = useMediaQuery('(max-width: 768px)');

  return (
    <Container sx={{marginX: '45px'}} className={s.footermobile}>
      <Box className={s.footermobile__upper}>
        <Box className={s.footermobile__upper__left}>
          <Avatar src={logo} style={{marginRight: '10px'}} />
          <Typography style={{fontSize: '15px', fontWeight: '600'}}>
            битуби
          </Typography>
          <Divider
            style={{margin: '0 10px'}}
            orientation="vertical"
            variant="middle"
          />
          <Typography style={{maxWidth: '100px', fontSize: '6px'}}>
            Для малого и среднего строительного бизнеса
          </Typography>
        </Box>

        <Box className={s.footermobile__upper__right}>
          <Box>
            <Avatar src={call} sx={{width: '20px', height: '20px'}} />
            <Typography style={{fontSize: '8px'}}>
              <a style={{color: '#707895'}} href="mailto:partners@bi-tu-bi.ru">
                partners@bi-tu-bi.ru
              </a>
            </Typography>
          </Box>
          <Box>
            <Avatar src={receive} sx={{width: '20px', height: '20px'}} />
            <Typography style={{fontSize: '8px'}}>
              <a style={{color: '#707895'}} href="tel:89262828813">
                +7 (926) 282-88-13‬
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box style={{marginBottom: '26px'}}>
        <Button
          style={{
            color: 'white',
            backgroundColor: '#558af0',
            fontSize: '8px',
            width: '55%',
            textTransform: 'none',
          }}
          onClick={() => handleOpen()}
        >
          Оставить заявку бесплатно
        </Button>
      </Box>

      <img style={{width: '100%'}} src={companies} alt="pic" />

      <Divider />

      <Box className={s.footermobile__lower}>
        <Typography style={{fontSize: '6px'}}>
          <Link
            href="https://docs.google.com/document/d/10-kqv4U8eTChwkWuIkgMD2UMsK7EEBhyUgHZpz5qu8Y/edit"
            color="inherit"
          >
            Политика обработки персональных данных
          </Link>
        </Typography>
        <Typography style={{fontSize: '6px'}}>
          <Link
            href="https://docs.google.com/document/d/1p8A_xutZG5AK6USW35AFAkonuSGMjaYeb-IEJ6Wc3do/edit"
            color="inherit"
          >
            Публичная оферта
          </Link>
        </Typography>
        <Typography style={{fontSize: '6px'}}>
          <Link
            href="https://docs.google.com/document/d/1MUhf1prNAzaSCjbvq2Byk7kQSJrY86vJDFu47wIuIFc/edit"
            color="inherit"
          >
            Условия использования
          </Link>
        </Typography>
      </Box>
      <FormsModal
        open={open}
        handleClose={handleClose}
        handleIconClose={handleIconClose}
        isMobile={matches ? true : false}
      />
    </Container>
  );
};

export default FooterMobile;
