import React, {FC, KeyboardEventHandler, MouseEventHandler, useEffect, useRef, useState} from 'react';

import './suggestionsInput.scss';
import ValidatableInput from '../validatableInput/validatableInputComponent';
import type {ValidatableInputPropsT} from '../validatableInput/validatableInputComponent';
import debounce from 'debounce';
import usePrevious from '../../hooks/usePrevious';

import './react-dadata.css';

const DEBUG = false;

export interface SuggestionsInputStateI<T> {
  records: T[];
  currentRecordIndex: number;
}

export interface SuggestionsInputRendrableOption<T> {
  suggestion: T;
  inputQuery: string;
}

interface SuggestionsInputPropsI<T>{
  minChars?: number;
  delay?: number;
  createRequestPromise: (value: string) => Promise<T[]>;
  substituteOnClick: (record: T) => string;
  manageSuggestionsState?: [SuggestionsInputStateI<T>, (s: SuggestionsInputStateI<T>) => void];
  manageValueState?: [string, (s: string) => void];
  validatableInputProps: ValidatableInputPropsT & {id: string},
  RenderOption: FC<SuggestionsInputRendrableOption<T>>,
  datalistClassName?: string,
}

function SuggestionsInputComponent<T>(props: SuggestionsInputPropsI<T>) {
  const {
    minChars,
    delay,
    createRequestPromise,
    substituteOnClick,
    RenderOption,
    datalistClassName = 'suggestions-input__suggestions',
  } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = props.manageSuggestionsState || useState<SuggestionsInputStateI<T>>({
    records: [],
    currentRecordIndex: -1,
  });
  const [keyboardSelectedIndex, setKeyboardSelectedIndex] = useState(-1);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, setValue] = props.manageValueState || useState('');

  const prevValue = usePrevious<string>(value) || '';

  useEffect(() => {
    const performFetchSuggestions = () => {
      // Проверяем на минимальное количество символов для отправки
      if (typeof minChars === 'number' && minChars > 0 && value.length < minChars) {
        setState({...state, records: [], currentRecordIndex: -1});
        return;
      }
      // не делать запрос после выбора через список
      // только что был сделан выбор -> фокус сбросился
      if (inputRef.current !== document.activeElement) {
        return;
      }
      if (DEBUG) console.log('[SuggestionsInput] emmiting request for ' + value);
      createRequestPromise(value).then((records) => {
        if (DEBUG) console.log('[SuggestionsInput] saving response for ' + value);
        setState({...state, records, currentRecordIndex: -1});
      });
    };
    if (prevValue === value) {
      return;
    }
    if (DEBUG) console.log('[SuggestionsInput] input changed: ', value, ' prev:', prevValue);
    delay ? debounce(performFetchSuggestions, delay) : performFetchSuggestions();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [state, setState, value, setValue, createRequestPromise, minChars, delay],
  );

  if (DEBUG) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => console.warn('[SuggestionsInput] state:', value, state));
  }

  const inputRef = useRef<HTMLInputElement>(null);

  // HANDLERS

  const onSuggestionClick: MouseEventHandler<HTMLButtonElement> =
  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    const idx = Number((e.currentTarget as HTMLButtonElement).dataset.index);
    if (DEBUG) console.log('[SuggestionsInput] click', idx, (e.currentTarget as HTMLButtonElement).dataset);
    if (state.records.length >= idx - 1) {
      setValue(substituteOnClick(state.records[idx]));
      setState({...state, currentRecordIndex: idx});
      inputRef.current?.blur();
    }
  };

  const onKeyDown:KeyboardEventHandler<HTMLInputElement> =
  (e: React.KeyboardEvent<HTMLInputElement>) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
    if (e.key === 'ArrowDown' && keyboardSelectedIndex + 1 < state.records.length) {
      setKeyboardSelectedIndex(keyboardSelectedIndex + 1);
    }
    if (e.key === 'ArrowUp' && keyboardSelectedIndex > 0) {
      setKeyboardSelectedIndex(keyboardSelectedIndex - 1);
    }
    if (e.key === 'Escape') {
      setKeyboardSelectedIndex(-1);
      inputRef.current?.blur();
    }
    if (e.key === 'Enter') {
      setValue(substituteOnClick(state.records[keyboardSelectedIndex]));
      setState({...state, currentRecordIndex: keyboardSelectedIndex});
      setKeyboardSelectedIndex(-1);
      inputRef.current?.blur();
    }
  };

  const onBlur: React.FocusEventHandler<HTMLInputElement> = () => {
    setKeyboardSelectedIndex(-1);
  };


  return (
    <div className='suggestions-input suggestions-input__container'>
      <ValidatableInput
        {...props.validatableInputProps}
        // TODO: accessibility
        // list={props.validatableInputProps.id + '_list'}
        autoComplete='off'
        valueState={[value, setValue]}
        innerRef={inputRef}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
      <datalist className={datalistClassName} id={props.validatableInputProps.id  + '_list'}>
        {/* TODO: снять ограничение на кол-во */}
        {state.records.slice(0, 15).map((record, idx) => {
          // let suggestionClass = state.suggestionClassName || 'suggestions-input__suggestion';
          let suggestionClass = 'suggestions-input__suggestion';
          if (idx === state.currentRecordIndex) {
            // suggestionClass += ` ${currentSuggestionClassName || 'suggestions-input__suggestion--current'}`;
            suggestionClass += ` ${'suggestions-input__suggestion--current'}`;
          }
          if (idx === keyboardSelectedIndex && idx !== state.currentRecordIndex) {
            suggestionClass += ' hover';
          }
          return (
            <button key={idx} data-index={idx} className={suggestionClass} onMouseDown={onSuggestionClick}>
              <RenderOption suggestion={record} inputQuery={value}/>
            </button>
          );
        })}
      </datalist>
    </div>
  );
}

export {SuggestionsInputComponent};
export default SuggestionsInputComponent;
