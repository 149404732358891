import React, {
  FC,
  ChangeEvent,
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  InputHTMLAttributes,
  useState,
  RefObject,
  useEffect,
  useRef,
} from 'react';
import usePrevious from '../../hooks/usePrevious';

import './validatableInput.scss';

export type ConditionMessageT = {
  msgOnTrue: string,
  condition: (value: string) => boolean,
}

const DEBUG = false;

export interface ValidatableInputPropsT extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  conditions: ConditionMessageT[],
  trimmed?: boolean,
  labelSign?: string,
  tooltipWidth?: string,
  tooltipErrorMessage?: string,
  validState?: [boolean, (t: boolean) => void],
  valueState?: [string, (s: string) => void],
  requiredMessage?: string,
  textarea?: boolean,
  dataLength?: number,
  innerRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>
}

/**
 * Компонент input с условиями валидации. Внизу полоска с числом пройденных проверок
 * При хотя бы одной ошибке текст становится красным, а при наведении справа выпадает
 * тултип со списком ошибок
 * @param {ConditionMessageT[]} conditions - массив условий, которые считаются ошибками,
 * и сообщений, которые им соответсвуют
 * @param {string} id - если указан labelSign, то требуется указать и id
 * @param {string} labelSign - подпись для label
 * @param {boolean} trimmed [= false] - если true, то текст, передаваемый на проверку триммируются,
 * т.е. нельзя ввести пробелы
 * @param {string} tooltipWidth [= '100px'] - ширина тултипа с подсказками
 * @param {string} tooltipErrorMessage [= 'Убедитесь, что выполнено:'] - заголовок перед списком ошибок на тултипе
 * @param {InputHTMLAttributes<HTMLInputElement>} ...rest - остальные атрбуты тега input
 * @return {JSX}
 */
const ValidatableInputComponent: FC<ValidatableInputPropsT> = ({
  conditions,
  id,
  labelSign,
  trimmed = false,
  tooltipWidth = '100px',
  tooltipErrorMessage = 'Убедитесь, что выполнено:',
  required = false,
  requiredMessage = 'Обязательное поле',
  validState,
  valueState,
  textarea = false,
  innerRef,
  dataLength,
  ...rest
}) => {
  if (labelSign && !id) {
    console.warn('ValidatableInputComponent: using labels requires setting an id');
  }

  const [isOnceBlured, setIsOnceBlured] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isValid, setIsValid] = validState || useState(true);
  const [falseCondMsgs, setFalseCondMsgs] = useState([] as string[]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [initialValue, handleValueChange] = valueState || useState('');
  const value =  trimmed ? initialValue.trim() : initialValue;

  const prevValue = usePrevious<string>(value);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRef = innerRef || (textarea ? useRef<HTMLTextAreaElement>(null) : useRef<HTMLInputElement>(null));

  useEffect(() => {
    if (DEBUG) console.log('[ValidatableInputComponent]', {prevValue}, {value});
    if (prevValue !== value) {
      if (DEBUG) console.log('[ValidatableInputComponent] changed from', prevValue, 'to', value);
      let isValid = true;
      const falseCondMsgsLocal: string[] = [];

      if (!value) {
        required ? setIsValid(false) : setIsValid(true);
        setFalseCondMsgs([...(required ? [requiredMessage] : []), ...(conditions.map(({msgOnTrue}) => msgOnTrue))]);
      } else {
        conditions.forEach(({condition, msgOnTrue}) => {
          if (condition(value)) {
            falseCondMsgsLocal.push(msgOnTrue);
          }
        });

        if (falseCondMsgsLocal.length > 0) {
          isValid = false;
        }
        setIsValid(isValid);
        setFalseCondMsgs(falseCondMsgsLocal);
      }
    }
  }, [value, prevValue, setIsValid, setFalseCondMsgs, handleValueChange, required, requiredMessage, conditions]);

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
  (e: ChangeEvent<HTMLInputElement>) => {
    handleValueChange(e.target.value);
    // вызов функции пользователя
    if (rest.onChange) {
      rest.onChange(e as ChangeEvent<HTMLInputElement>);
    }
  };

  // в React onChange и onInput - одно и то же
  // onInput не отслеживаем
  // https://stackoverflow.com/questions/38256332/in-react-whats-the-difference-between-onchange-and-oninput

  // const handleInput = (e: FormEvent<HTMLInputElement>) => {
  //   if (DEBUG) console.log('input:', e.currentTarget.value)
  //   // originalOnInput(e);
  // }

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> =
  (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsOnceBlured(true);
    // вызов функции пользователя
    if (rest.onBlur) {
      rest.onBlur(e);
    }
  };

  return (
    <div className={'validatable-input input-field ' +
      ((isOnceBlured && !isValid) || (dataLength && value.length > dataLength) ? 'invalid ' : '') +
        (rest.className || '')
    }
    >
      {!textarea ? <input
        // https://stackoverflow.com/questions/31163693/how-do-i-conditionally-add-attributes-to-react-components
        id={id}  // если undefined, то это ок
        type={rest.type || 'text'}
        ref={inputRef as RefObject<HTMLInputElement>}
        value={value}
        {...rest}
        data-length={dataLength || false}
        required={false}
        onChange={handleChange}
        onBlur={handleBlur}
        className=''  // важно, чтобы был пустой класс
      /> : <textarea
      // https://stackoverflow.com/questions/31163693/how-do-i-conditionally-add-attributes-to-react-components
        id={id}  // если undefined, то это ок
        type={rest.type || 'text'}
        ref={inputRef as RefObject<HTMLTextAreaElement>}
        value={value}
        data-length={dataLength || false}
        {...rest}
        required={false}
        onChange={handleChange}
        onBlur={handleBlur}
        className='materialize-textarea'
      />}

      <div className={'validatable-input__error-msg'}
        style={{height: tooltipWidth, bottom: '-' + tooltipWidth}}
      >
        {falseCondMsgs.length ? <>
          <p>{tooltipErrorMessage}</p>
          {falseCondMsgs.map((msg, idx) => (
            <p key={idx}>{msg}</p>
          ))}
        </> : ''}
      </div>

      <div className={'validatable-input__underline'}
        style={{
          '--validatable-input-error-percentage':
          isOnceBlured ? Math.floor(
            // обязательность добавляет дополнительное сообщение, но его не считаем, иначе возможно 200%
            // вычитаем 1, когда не заполнено обязательное поле и есть что вычитать
            conditions.length && !isValid ? (
              (falseCondMsgs.length - ((required && !value && falseCondMsgs.length) ? 1 : 0)) * 100 / conditions.length
            ) : 0,
          ) + '%' : '0%',
          '--validatable-input-lines-offset': `${!falseCondMsgs.length ? 0 : 5}px`,
        } as React.CSSProperties}
      ></div>

      {dataLength && value ?
        <span className={'validatable-input__character-counter' + (value.length > dataLength ? ' invalid' : '')} >
          {value.length}/{dataLength}
        </span> : ''
      }


      {labelSign ? <label htmlFor={id}>{labelSign}</label> : ''}

      {/* debug */}
      {
        DEBUG ? <>
          {!isOnceBlured ? <p>hidden</p> : (
            <>
              {
                !inputRef?.current?.value ?
                  <p>пусто</p> :
                  <>
                    <p>value: {value}</p>
                    <p>{Math.floor(
                      (falseCondMsgs.length - (
                        (required && !value && falseCondMsgs.length) ? 1 : 0)) * 100 / conditions.length,
                    )}% -&gt; {`${isValid}`}</p>
                  </>
              }
              {falseCondMsgs.map((msg, idx) => (
                <p key={idx}>{msg}</p>
              ))}
            </>
          )}
        </> : ''
      }
    </div>
  );
};

export default ValidatableInputComponent;
