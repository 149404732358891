import {createSlice} from '@reduxjs/toolkit';
import {ChatData} from '../../pages/ChatPage/types';

type userStateType = {
  data: ChatData[] | null;
  status: 'init' | 'loading' | 'success' | 'error';
  isNewChat: boolean;
  error: string | undefined;
};

const initialState: userStateType = {
  data: null,
  status: 'init',
  isNewChat: false,
  error: undefined,
};

const chatListSlice = createSlice({
  name: 'chatlist',
  initialState,
  reducers: {
    getChatListAction: (state, action) => {
      state.data = action.payload;
    },
    changeStatusListAction: (state, action) => {
      state.isNewChat = action.payload;
    },
  },
});

export const {reducer: chatListReducer, actions: chatlistActions} =
  chatListSlice;
export const {getChatListAction, changeStatusListAction} = chatListSlice.actions;
