/* eslint-disable */
import React, {FC, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import addItem from '../../redux/reducers/cartReducer';

import HeartIcon from '../../images/heart.svg';
import SquaresIcon from '../../images/squares.svg';
import ClipboardIcon from '../../images/clipboardtext.svg';
import StarIcon from '../../images/star.svg';
import DefaultImg from '../../images/default.png';

import './card.scss';
import Ajax from '../../api/ajax';
import CategoriesData from '../CategoryCard/categories_data';

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars*/

interface CardPropsI {
  id: number;
  name: string;
  price: number;
  photo: string;
  description: string;
  classList: string;
  category_id?: number;
  category_name?: string;
  currentUser: any;
  legalName?: string;
  companyId?: number | string;
}
interface ItemResponseI {
  id: number;
  name: string;
  description: {
    String: string;
    Valid: boolean;
  };
  price: number;
  photo: string;
  company: {
    owner_id: number;
  };
}

type ExistChatResponse = {
  chat_id: number;
  create_new_chat: string;
};
const Card: FC<CardPropsI> = ({
  id,
  name,
  price,
  category_id,
  category_name,
  photo,
  description,
  classList,
  currentUser,
  legalName,
  companyId
}) => {
  const [numberOfItems, setNumberOfItems] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation().pathname;

  const [existChat, setExistChat] = React.useState<any>();
  const [itemData, setItemData] = useState({
    id: 0,
    name: '',
    description: {
      String: '',
      Valid: true,
    },
    price: 0,
    photo: '',
    company: {
      owner_id: 0,
    },
  });

  const getId = (location: string) => {
    return location.substring(location.length, location.lastIndexOf('/') + 1);
  };
  const productUrl = `/categories/${CategoriesData.find((val) => val.name === category_name)?.translatedName}/${id}`;
  // const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  //   Ajax.get({
  //     url: `/product/${getId(location)}`,
  //   }).then(({status, response}) => {
  //     if (status === Ajax.STATUS.ok) {
  //       setItemData(response.data as ItemResponseI);
  //       dispatch(getChatId(response?.data));
  //       console.log(response.data);
  //     } else {
  //       console.warn(response.msg);
  //     }
  //   });

  //   if (getId(location) !== undefined && Number.isNaN(getId(location))) {
  //     console.log(
  //       '!Number.isNaN(getId(location)',
  //       Number.isNaN(getId(location)),
  //       location,
  //     );
  //     Ajax.get({
  //       url: `/product/${getId(location)}`,
  //     }).then(({status, response}) => {
  //       if (status === Ajax.STATUS.ok) {
  //         setItemData(response.data as ItemResponseI);
  //         console.log(response.data);
  //       } else {
  //         console.warn(response.msg);
  //       }
  //     });
  //   }

  //   if (getId(location) !== undefined) {
  //     Ajax.get({
  //       url: `/product/${getId(location)}`,
  //     }).then(({status, response}) => {
  //       if (status === Ajax.STATUS.ok) {
  //         setItemData(response.data as ItemResponseI);
  //       } else {
  //         console.warn(response.msg);
  //       }
  //     });
  //   }
  // }, [location]);
  // const checkChat = () => {
  //   if (getId(location) !== undefined) {
  // Ajax.post({
  //   url: `/chat/init`,
  //   body: {
  //     sender_id: currentUser.id,
  //     product_id: itemData.id,
  //   },
  // }).then(({status, response}) => {
  //   if (status === Ajax.STATUS.ok) {
  //     setExistChat(response.data);
  //     if ((response?.data as any).create_new_chat == false) {
  //       navigate(`/chat/${(response?.data as any).chat_id}`);
  //     } else {
  //       navigate(`/chat`);
  //     }
  //     console.log(response.data);
  //   } else {
  //     console.warn('error');
  //   }
  // });
  //   }
  // };
  const validateNumberOfItems = (n: number): number => {
    if (n < 0) {
      setNumberOfItems(0);
      return 0;
    }
    return n;
  };

  const handleGoCompany = () => {
    navigate(`/company/${companyId}`)
  }

  if (!classList.includes('list-layout')) {
    return (
      <div data-id={id} className={classList}>
        <Link to={productUrl}>
          <img
            src={photo == null ? DefaultImg : photo}
            className="card__img"
            alt="Не удалось загрузить изображение"
          />
        </Link>
        <div className="card__header">
          <div className="card__rating">
            <img src={StarIcon} alt="star" />
            <img src={StarIcon} alt="star" />
            <img src={StarIcon} alt="star" />
            <img src={StarIcon} alt="star" />
            <img src={StarIcon} alt="star" />5
          </div>
          <div className="card__articul">000001</div>
        </div>
        <Link to={productUrl}>
          <h4 className="card__name">{name}</h4>
        </Link>
        <span className="card__company" onClick={handleGoCompany}>{legalName}</span>
        <div className="card__pricing">
          Цена за
          <span className="card__pricing-item">кв.м</span>
          <span className="card__pricing-item">штуку</span>
        </div>
        <div className="card__helper">
          <span className="card__price">{price} ₽</span>
          <div className="card__icons">
            <a href="/" className="card__icon">
              <img src={HeartIcon} alt="favorite" />
            </a>
            <a href="/" className="card__icon">
              <img src={SquaresIcon} alt="favorite" />
            </a>
            <a href="/" className="card__icon">
              <img src={ClipboardIcon} alt="favorite" />
            </a>
          </div>
        </div>
        <div className="card__footer">
          <button
            onClick={() => {
              currentUser !== null
                ? Ajax.post({
                    url: `/chat/init`,
                    body: {
                      sender_id: currentUser.id,
                      product_id: id,
                    },
                  }).then(({status, response}) => {
                    if (status === Ajax.STATUS.ok) {
                    
                      if ((response?.data as any).create_new_chat == false) {
                        navigate(`/chat/${id}`);
                      } else {
                        navigate(`/chat`);
                      }
                      console.log(response.data);
                    } else {
                      console.warn('error');
                    }
                  })
                : navigate('/register');
            }}
            className="btn card__btn theme-blue"
          >
            Купить
          </button>
          <div className="card__counter">
            <button
              onClick={() => setNumberOfItems((prevNum) => prevNum - 1)}
              className="btn theme-blue card__counter-btn"
            >
              -
            </button>
            {validateNumberOfItems(numberOfItems)}
            <button
              onClick={() => setNumberOfItems((prevNum) => prevNum + 1)}
              className="btn theme-blue card__counter-btn"
            >
              +
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div data-id={id} className={classList}>
        <Link to={productUrl}>
          <img
            src={photo == null ? DefaultImg : photo}
            className="card__img"
            alt="Не удалось загрузить изображение"
          />
        </Link>
        <div>
          <div className="card__header">
            <Link to={productUrl}>
              <h4 className="card__name">{name}</h4>
            </Link>
            <div className="card__descr">{description}</div>
            <div className="card__info">
              <div>
                <div className="card__pricing">
                  Цена за
                  <span className="card__pricing-item">кв.м</span>
                  <span className="card__pricing-item">штуку</span>
                </div>
                <span className="card__price">{price}</span>
              </div>
              <div className="card__icons">
                <a href="/" className="card__icon">
                  <img src={HeartIcon} alt="favorite" />
                </a>
                <a href="/" className="card__icon">
                  <img src={SquaresIcon} alt="favorite" />
                </a>
                <a href="/" className="card__icon">
                  <img src={ClipboardIcon} alt="favorite" />
                </a>
              </div>
            </div>
          </div>
          <div className="card__footer">
            <button className="btn card__btn theme-blue">В корзину</button>
            <div className="card__counter">
              <button
                onClick={() => setNumberOfItems((prevNum) => prevNum - 1)}
                className="btn theme-blue card__counter-btn"
              >
                -
              </button>
              {validateNumberOfItems(numberOfItems)}
              <button
                onClick={() => setNumberOfItems((prevNum) => prevNum + 1)}
                className="btn theme-blue card__counter-btn"
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export {Card};
export default Card;
