import {
  Box,
  Stack,
  Typography,
  Avatar,
  IconButton,
  Button,
} from '@mui/material';
import React from 'react';
import tapImg from '../../../static/aboutPage/tapImg.png';
import styles from './mainBlock.module.scss';
import logo from '../../../static/landing/logo.svg';

export default function MainBlock() {
  return (
    <Box
      component={'section'}
      sx={{
        position: 'relative',
        maxWidth: '1314px',
        width: '100%',
        heigh: '592px',
        margin: '0 64px',
        backgroundColor: '#EDF1F8',
        borderRadius: '24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        overflow: 'hidden',
      }}
    >
      <Stack
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        sx={{padding: '40px 48px'}}
      >
        <Box sx={{width: '320px', height: '60px', display: 'flex'}}>
          <IconButton>
            <Avatar
              src={logo}
              alt="Logo"
              sx={{width: '60px', height: '60px'}}
            />{' '}
          </IconButton>

          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '15px',
            }}
          >
            <Typography fontSize={22}>битуби</Typography>
            <Typography fontSize={10} color={'grey'}>
              Для малого и среднего строительного бизнеса
            </Typography>
          </Stack>
        </Box>
        <Typography
          sx={{
            width: '600px',
            fontSize: '48px',
            fontWeight: '600',
            lineHeight: '64px',
          }}
        >
          Онлайн-площадка для торговли строительными материалами
        </Typography>
        <Button
          variant="contained"
          sx={{
            width: '302px',
            height: '64px',
            borderRadius: '10px',
            textTransform: 'none',
          }}
        >
          {' '}
          Подробно о плащадке &#129123;
        </Button>
      </Stack>
      <Box
        sx={{
          borderRadius: '24px',
          width: '416px',
          height: '590px',
          background: 'linear-gradient(209deg, #83ACFC 13.57%, #2E55DE 115.1%)',
        }}
      >
        <img src={tapImg} alt="tap" className={styles.tapImage} />
      </Box>
    </Box>
  );
}
