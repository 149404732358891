import React from 'react';
import Header from '../../components/header/headerComponent';
import Footer from '../../components/Footer';
import MainBlock from './MainBlock';
import CardsBlock from './CardsBlock';
import ParnersBlock from './PartnersBlock';
import TextBlock from './TextBlock';
// import HowPaySharesBlock from './HowPaySharesBlock';

const AboutPage: React.FC = () => {
  return (
    <>
      <Header />
      <MainBlock />
      <CardsBlock />
      <ParnersBlock />
      <TextBlock />
      {/* TODO: Контент ниже в фигме предоставлен как картинка. */}
      {/* <HowPaySharesBlock /> */}
      <Footer />
    </>
  );
};

export default AboutPage;
