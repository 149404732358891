/* eslint-disable */
import {FC} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {changeCategory} from '../../redux/reducers/categoriesReducer';
import style from './style.module.scss';
import iconArrowRight from '../../static/icons/categories/IconArrowRight.svg';

type CategoryCardT = {
  categoryName: string;
  translatedCategory: any;
  img: any;
};

const CategoryCard: FC<CategoryCardT> = (props) => {
  const dispatch = useAppDispatch();

  return (
    <div
      className={style.categoryCard}
      onClick={() => {
        dispatch(changeCategory(props.translatedCategory));
        localStorage.setItem('CategoryName', props.translatedCategory);
      }}
    >
      <Link
        to={`/categories/${props.translatedCategory}`}
        className={style.categoryCard__content}
      >
        <img
          src={props.img}
          alt="category_card_img"
          className={style.categoryCard__image}
        />

        <h6 className={style.categoryCard__text}>{props.categoryName}</h6>
        <img src={iconArrowRight} alt="arrow" className={style.categoryCard__arrow} />
      </Link>
    </div>
  );
};

export default CategoryCard;
