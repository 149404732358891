import {createSlice} from '@reduxjs/toolkit';

type productStateType = {

  data: {
    id: number;
    name: string;
    description: {
      string: string;
      valid: boolean;
    };
    docs: string;
    price: number;
    photo: Array<string>;
    company: {
      id: number;
      name: string;
      description: string;
      legal_name: string;
      itn: string;
      psrn: string;
      address: string;
      legal_address: string;
      email: string;
      phone: string;
      link: string;
      activity: string;
      owner_id: number;
      rating: number;
      verified: number;
    };
  };

  status: 'init' | 'loading' | 'success' | 'error';
  error: string | undefined;
};

const initialState: productStateType = {
  data: {
    id: -1,
    name: '',
    description: {
      string: '',
      valid: false,
    },
    price: 1,
    photo: [],
    docs: '',
    company: {
      id: -1,
      name: '',
      description: '',
      legal_name: '',
      itn: '',
      psrn: '',
      address: '',
      legal_address: '',
      email: '',
      phone: '',
      link: '',
      activity: '',
      owner_id: -1,
      rating: -1,
      verified: -1,
    },
  },
  status: 'init',
  error: undefined,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getProductID: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const {reducer: productReducer, actions: productActions} = productSlice;
export const {getProductID} = productSlice.actions;
