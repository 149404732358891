/* eslint-disable */
import React, {useEffect} from 'react';
import {Box, Typography, FormControl} from '@mui/material';
import style from './windowchat.module.scss';
import useDebounce from '../../../hooks/useDebounce';
import Ajax from '../../../api/ajax';
import {useNavigate, useParams} from 'react-router';
import Message from '../Message/Message';
import {MessagesData} from '../types';
import {useAppSelector, useAppDispatch} from '../../../redux/hooks';
import {currentUserType, ChatData} from '../types';
import {WS_API_URL} from '../../../utils/globals';
import {getProductID} from '../../../redux/reducers/productReducer';
import {WSActions} from '../../../redux/reducers/wsReducer';
import store from '../../../redux/store';
import {changeStatusListAction} from '../../../redux/reducers/chatListReducer';

interface propsEvent {
  preventDefault: () => void;
  target: {
    value: React.SetStateAction<string>;
  };
}
interface WindowChatPropsType {
  currentUser: currentUserType;
  dataChat: ChatData[];
}
let wsChat: WebSocket;

function WindowChat({currentUser, dataChat}: WindowChatPropsType) {
  const [initMsgSent, setInitMsg] = React.useState(false);
  const status = store.getState().WSReducer.status;
  if (
    store.getState().WSReducer.status != 'connected' &&
    store.getState().WSReducer.status != 'connecting' &&
    store.getState().WSReducer.status != 'reconnecting'
  ) {
    store.dispatch(WSActions.start_connecting());
    wsChat = new WebSocket(WS_API_URL);
    wsChat.onopen = () => {
      store.dispatch(WSActions.connect());
      console.log('websocket: connected');
    };
    console.log('websocket: start new ws: ', store.getState().WSReducer.status);
  } else {
    console.log(
      'websocket: in progres: ',
      store.getState().WSReducer.status,
      ' Ws: ',
      wsChat,
    );
    if (wsChat != undefined) {
      wsChat.onopen = () => {
        store.dispatch(WSActions.connect());
        console.log('websocket: connected');
      };
      wsChat.onmessage = function(e) {
        setCount(count + 1);
        console.log('websocket: new msg', e);
      };
      wsChat.onclose = function() {
        store.dispatch(WSActions.disconnect());
        setInitMsg(false);
        console.log('websocket: closed');
      };
    }
  }
  const [text, setText] = React.useState('');
  const {data: currentProduct} = useAppSelector(
    (state) => state.productReducer,
  );
  // стэйт для сообщений отправляющий через вебсокеты
  // const [fetchingMsg, setFetchingMsgs] = React.useState(true);
  const debouncedSearchReq = useDebounce('NULL', 500);
  const dispatch = useAppDispatch();

  // стэйт для всех сообщений приходящих с сервера
  const [msgs, setMsgs] = React.useState<MessagesData[]>([]);
  const param = useParams();
  const chatId = param.id;
  const currentChat =
    dataChat && dataChat.find((item) => `${item.chat.id}` === chatId);

  const navigate = useNavigate();

  const [ticking, setTicking] = React.useState(true);
  const [count, setCount] = React.useState(0);
  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  useEffect(() => {
    scrollToBottom();
  }, [msgs.length]);

  useEffect(() => {
    if (!dataChat) navigate('/chat');
  }, [dataChat]);

  useEffect(() => {
    if (debouncedSearchReq !== '') {
      Ajax.get({
        url: `/product/${currentChat?.chat.product_id}`,
      }).then(({status, response}) => {
        if (status === Ajax.STATUS.ok) {
          dispatch(getProductID(response?.data));
        }
      });
    }
  }, [currentChat, debouncedSearchReq]);

  useEffect(() => {
    const timer = setTimeout(() => ticking && setCount(count + 1), 2 * 1000);
    return () => clearTimeout(timer);
  }, [count, ticking]);

  useEffect(() => {
    if (debouncedSearchReq !== '') {
      Ajax.get({
        url: `/chat/${chatId}`,
      })
        .then(({status, response}) => {
          if (status === Ajax.STATUS.ok) {
            if (Array.isArray(response.data)) {
              setMsgs(response.data as MessagesData[]);
              console.log('msgs', response.data);
            }
          } else {
            console.warn(response.msg);
          }
        })
        .finally(() => {
          console.log('count', count);
          console.log('WS STATUS', store.getState().WSReducer.status);
          if (!initMsgSent) {
            if (
              currentUser?.id != undefined &&
              store.getState().WSReducer.status == 'connected'
            ) {
              const InitMsg = {
                sender_id: currentUser?.id,
              };
              wsChat.send(JSON.stringify(InitMsg));
              console.log('Send init msg', InitMsg);
              setInitMsg(true);
            } else {
              console.warn(
                'Current user undefined or ws not connected',
                currentUser?.id,
                store.getState().WSReducer.status,
              );
            }
          }
        });
    }
  }, [count]);

  function messageChange(evt: propsEvent) {
    evt.preventDefault();
    setText(evt.target.value);
  }

  const handleSendMessage = (event: any) => {
    event.preventDefault();
    console.log(event.key === 'Enter');

    if (!text) {
      return;
    }

    let recIdTemp = currentProduct.id;
    if (msgs.length != 0) {
      recIdTemp =
        msgs[0].receiver_id == currentProduct.company.owner_id ?
          msgs[0].sender_id : msgs[0].receiver_id;
    }

    const newMsg = {
      text: text,
      chat_id: Number(chatId),

      sender_id:
        currentProduct.company.owner_id == currentUser.id ?
          currentProduct.company.owner_id : currentUser.id,

      receiver_id:
        currentProduct.company.owner_id == currentUser.id ?
          recIdTemp : currentProduct.company.owner_id,
      type: 'string',
    };
    wsChat.send(JSON.stringify(newMsg));
    dispatch(changeStatusListAction(true));
    setText('');
  };

  const date = new Date();
  const options = {
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
  };
  const formattedDate = date.toLocaleDateString('ru-US', options as any);

  return (
    <Box className={style.windowchat}>
      <Box
        className={
          msgs.filter((curent) => curent.chat_id == Number(chatId)).length > 4 ?
            style.windowchat__message_scroll : style.windowchat__message
        }
      >
        <Typography
          className={style.windowchat__data}
          sx={{
            margin: '0 auto 30px auto',
          }}
        >
          {formattedDate}
        </Typography>
        {msgs &&
          msgs
            .filter((current) => current.chat_id == Number(chatId))
            // .reverse()
            // .slice(0, 10)
            .map((item, index) => {
              return (
                <Message
                  key={index}
                  messages={item}
                  currentUser={currentUser}
                />
              );
            })}
        <Box component={'div'} ref={messagesEndRef} />
      </Box>
      <FormControl
        className={style.windowchat__form}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '15px',
          padding: '15px 25px',
          borderRadius: '8px',
          border: '1px solid #1d365324',
        }}
      >
        <label htmlFor="file-input" className={style.input__label}>
          <input id="file-input" type="file" className={style.input__file}/>
          <div className={style.windowchat__scrap} />
        </label>

        <input
          className={style.windowchat__input}
          type="text"
          id="message-sender"
          value={text}
          onChange={messageChange}
          placeholder="Сообщение"
          maxLength={500}
          required
          onKeyUp={(e) => e.key === 'Enter' && handleSendMessage(e)}
        />
        <button
          onClick={(e) => handleSendMessage(e)}
          className={style.windowchat__send}
        />
      </FormControl>
      <Box
        width={'283px'}
        height={'40px'}
        sx={{
          backgroundColor: '#558AF0',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
          gap: '10px',
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '14px',
            whiteSpace: 'nowrap',
          }}
        >
          Индивидуальное предложения
        </Typography>
      </Box>
    </Box>
  );
}
export default WindowChat;
