/* eslint-disable*/
import React, {FC, useEffect, useState} from 'react';
import CategoryCard from '../components/CategoryCard/CategoryCard';
import CategoriesData from '../components/CategoryCard/categories_data';
import RootPage from './rootPage';
import style from './categoryPageStyles.module.scss';
import {useMediaQuery} from '@mui/material';
import {Link} from 'react-router-dom';

const CategoriesPage: FC = () => {
  const matchesMobile = useMediaQuery('(max-width: 480px)');
  return (
    <RootPage nameOfActive="Categories">
      <div className={style.categoryPage}>
        {matchesMobile && (
          <div className={style.categoryPage__title}>
            <Link to="/">
              <div className={style.categoryPage__close} />
            </Link>

            <h1 className={style.categoryPage__titleText}>Категории</h1>
          </div>
        )}
        <div className={style.categoryPage__content}>
          {CategoriesData.map((val, i) => (
            <CategoryCard
              categoryName={val.name}
              translatedCategory={val.translatedName}
              img={val.pic}
              key={i}
            />
          ))}
        </div>
      </div>
    </RootPage>
  );
};

export default CategoriesPage;
