/* eslint-disable */
import React, {FormEventHandler, useState} from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
} from '@mui/material';
import s from './secondstep.module.scss';
import IModalWithData from '../../../pages/landing/types/IModalWithData';
import logo from '../../../static/landing/logoo.svg';
import closeIcon from '../../../static/landing/addcircle.svg';
import ValidatableInput from '../../validatableInput/validatableInputComponent';
import ModalThanks from '../Thanks';
import regexpValidation from '../../../utils/regexp';
import Ajax from '../../../api/ajax';
import {useNavigate} from 'react-router';
import ModalHowDItWork from '../HowDoesItWork';
import ItnLandingForm from '../../../libs/components/dadata/ItnLandingForm/ItnLandingForm';
import {Company} from '../../../pages/forms/register';

const ModalSecondStep: React.FC<IModalWithData> = (props) => {
  const {open, handleClose, handleIconClose, data, isMobile} = props;

  const matches = useMediaQuery('(max-width: 768px)');

  const [open1, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState('');
  const [itn, setItn] = useState('');
  const [company, setCompany] = useState<Company>({
    legalName: '',
    brandName: '',
    address: '',
    opf: '',
  });
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [, setSrvErr] = useState('');
  const handleClose1 = () => setOpen(false);
  const handleIconClose1 = () => {
    setOpen(false);
  };

  const handleClose2 = () => setOpen2(false);
  const handleIconClose2 = () => {
    setOpen2(false);
  };

  const navigate = useNavigate();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const submitHandler: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (
      data.product_category &&
      data.delivery_address &&
      data.delivery_date &&
      data.order_text &&
      email &&
      itn &&
      company
    ) {
      setOpen(true);
      Ajax.post({
        url: '/landingOrder',
        body: {
          product_category: data.product_category,
          delivery_address: data.delivery_address,
          delivery_date: data.delivery_date,
          order_text: data.order_text,
          email: email,
          itn: itn,
          company: company,
        },
      }).then(({status, response}) => {
        if (status === Ajax.STATUS.ok) {
          console.warn('ok');
          navigate('/main');
        } else {
          console.warn(response.msg);
          setSrvErr(response.msg);
        }
      });
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        className={s.wrapper}
        sx={
          isMobile
            ? {
                paddingX: '15px',
                maxWidth: '100vw',
                paddingY: '20px',
                maxHeight: '90vh',
              }
            : {
                paddingX: '40px',
                paddingTop: '43px',
                paddingBottom: '30px',
                width: '560px',
                height: 'fit-content - 5%'
              }
        }
      >
        <IconButton
          sx={{
            right: 0,
            top: 0,
            marginRight: '5px',
            marginBottom: '3px',
            position: 'absolute',
          }}
          onClick={() => setOpen(false)}
        >
          <img src={closeIcon} alt="" />
        </IconButton>
        <img src={logo} alt="logo" />
        <Box className={s.wrapper__info}>
          <Typography>Осталось чуть-чуть</Typography>
          <Typography>
            Заполните оставшиеся поля, чтобы получать коммерческие предложения
            на почту.
          </Typography>
        </Box>
        <Box component="form" onSubmit={submitHandler}>
          <ValidatableInput
            id="actor-email"
            type="text"
            labelSign="E-mail"
            trimmed={true}
            required={true}
            conditions={regexpValidation.email.conditions}
            valueState={[email, setEmail]}
            validState={[isEmailValid, setIsEmailValid]}
            className={s.form_block}
          />

          <Box className={s.form_block}>
            <ItnLandingForm
              manageITNState={[itn, setItn]}
              manageCompanyState={[company, setCompany]}
            />
          </Box>
          <FormControlLabel
            label="Запомнить меня"
            control={<Checkbox checked={checked} onChange={handleChange} />}
            style={{color: 'black'}}
          />
          <Box className={s.buttonGroup}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                color: 'white',
                width: '30%',
                height: '44px',
                fontSize: '16px',
                marginRight: '30px',
              }}
              className={s.sendButton}
            >
              Отправить
            </Button>
            <Button
              variant="contained"
              sx={{
                color: 'black',
                width: '30%',
                height: '44px',
                fontSize: '16px',
              }}
              style={{backgroundColor: 'white', boxShadow: 'none'}}
              onClick={() => navigate('/register')}
            >
              Регистрация
            </Button>
          </Box>
        </Box>
        <Button
          style={{marginTop: '10px', fontSize: '16px'}}
          className={s.footer}
          onClick={() => setOpen2(true)}
        >
          Остались вопросы?
        </Button>
        <ModalThanks
          open={open1}
          handleClose={handleClose1}
          handleIconClose={handleIconClose1}
          isMobile={matches ? true : false}
        />
        <ModalHowDItWork
          open={open2}
          handleClose={handleClose2}
          handleIconClose={handleIconClose2}
          isMobile={matches ? true : false}
        />
      </Box>
    </Modal>
  );
};

export default ModalSecondStep;
