/* eslint-disable */
import React from 'react';
import IModal from '../../../pages/landing/types/IModal';
import s from './thanks.module.scss';
import logo from '../../../static/landing/logoo.svg';
import closeIcon from '../../../static/landing/addcircle.svg';
import {
  Modal,
  Box,
  IconButton,
  Typography,
  Button,
  Stack,
  useMediaQuery,
} from '@mui/material';
import {useNavigate} from 'react-router';
import ModalHowDItWork from '../HowDoesItWork';

const ModalThanks: React.FC<IModal> = ({
  open,
  handleClose,
  handleIconClose,
  isMobile,
}) => {
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width: 768px)');
  const [open1, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose1 = () => setOpen(false);
  const handleIconClose1 = () => {
    setOpen(!open);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={
          isMobile
            ? {paddingX: '15px', maxWidth: '100vw', paddingY: '20px'}
            : {
                paddingX: '40px',
                paddingTop: '43px',
                paddingBottom: '30px',
                width: '560px',
              }
        }
        className={s.wrapper}
      >
        <IconButton
          sx={{
            right: 0,
            top: 0,
            marginRight: '5px',
            marginBottom: '3px',
            position: 'absolute',
          }}
          onClick={handleIconClose}
        >
          <img src={closeIcon} alt="" />
        </IconButton>
        <img src={logo} alt="logo" />
        <Box className={s.main}>
          <Typography>Заявка получена!</Typography>
          <Typography>
            Мы уже подбираем поставщиков. Вы также можете создать аккаунт для
            удобной работы.
          </Typography>
        </Box>
        <Stack>
          <Button
            variant="contained"
            sx={{
              color: 'white',
              width: '50%',
              height: '44px',
              fontSize: `${isMobile ? '12px' : '16px'}`,
              marginRight: '30px',
            }}
            className={s.sendButton}
            onClick={() => navigate('/register')}
          >
            Зарегестрироваться
          </Button>
          <Button
            style={{
              marginTop: '30px',
              fontSize: `${isMobile ? '12px' : '16px'}`,
              maxWidth: '50%',
              textAlign: 'left',
            }}
            className={s.footer}
            onClick={() => handleOpen()}
          >
            Остались вопросы?
          </Button>
        </Stack>
        <ModalHowDItWork
          open={open1}
          handleClose={handleClose1}
          handleIconClose={handleIconClose1}
          isMobile={matches ? true : false}
        />
      </Box>
    </Modal>
  );
};

export default ModalThanks;
