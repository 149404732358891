const MOCKED_NET_DELAY = 500;

/* eslint-disable @typescript-eslint/no-explicit-any */
const wrapStandartServerResponse = (data: any, msg: string, status: number): {
  msg: string; status: number; data: any;
} => ({
  msg, status, data,
});

export default function mockedNetCall(data: any, fail = false): Promise<{
  status: number; response: {
    msg: string;
    status: number;
    data: any;
  };
}> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const answer = !fail?
        wrapStandartServerResponse(data, 'success (mock)', 200) :
        wrapStandartServerResponse({}, 'error (mock)', 400);
      console.log('mock resolved', answer);
      return !fail ?
        resolve({status: 200, response: answer}) :
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({status: 400, response: answer});
    }, MOCKED_NET_DELAY);
  });
}
