/* eslint-disable */
import {FC, useState} from 'react';
import s from './stats.module.scss';
import {Box, Button, Container, Typography, useMediaQuery} from '@mui/material';
import ModalHowDItWork from '../../../../components/Modals/HowDoesItWork';
import FormsModal from '../../../../components/Modals/FormsModal';

const Stats: FC = () => {
  const matches = useMediaQuery('(max-width: 768px)');
  const matchesMobile = useMediaQuery('(max-width: 576px)');
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose = () => setOpen(false);
  const handleIconClose = () => {
    setOpen(!open);
  };
  return (
    <Box component="section" className={s.grid}>
      <Container>
        <Typography>в 2 раза</Typography>
        <Typography>экономия времени на поиск поставщиков.</Typography>
      </Container>
      <Container>
        <Typography>на 20%</Typography>
        <Typography>выгоднее чем привычные поставщики.</Typography>
      </Container>
      <Container>
        <Typography>в 2 раза</Typography>
        <Typography>больше воронка поставщиков.</Typography>
      </Container>
      {matches ? (
        <Button
          style={
            !matchesMobile
              ? {backgroundColor: '#70789526'}
              : {
                  fontWeight: 'bold',
                  color: 'black',
                  fontSize: 10,
                  textTransform: 'none',
                  marginTop: 18,
                  backgroundColor: '#70789526',
                }
          }
          onClick={handleOpen}
          fullWidth
        >
          Как это работает?
        </Button>
      ) : null}
      <ModalHowDItWork
        open={open}
        handleClose={handleClose}
        handleIconClose={handleIconClose}
        isMobile={matches ? true : false}
      />
    </Box>
  );
};

export default Stats;
