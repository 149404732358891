/* eslint-disable @typescript-eslint/no-unused-vars */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootStateT} from '../store';
import type {FastRegisterRequestI} from '../../api/messageTypes';

export interface UserI {
  login: string,
  firstName: string,
  lastName: string,
  email: string,
}

interface AuthStateI extends FastRegisterRequestI {
  isAuthorized: boolean,
  token: string,
}

export interface ApiResponseLoginI {
  token: string,
  login: string,
  firstName?: string,
  lastName?: string,
  email?: string,
}

export interface ApiResponseUpdateI {
  login?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
}

const initialState: AuthStateI = {
  isAuthorized: false,
  token: '',
  // id: 0,
  name: '',
  password: '',
  owner_name: 'загрузка',
  surname: 'загрузка',
  patronymic: 'загрузка',
  // description: '',
  legal_name: '',
  itn: '',
  country: 'Российская Федерация',
  post: 'загрузка',
  // psrn: '',
  // address: '',
  // legal_address: '',
  email: '',
  // phone: '',
  // link: '',
  // activity: '',
  // owner_id: 0,
  // rating: 0,
  // docs: null,
};

const updateUser = (state: AuthStateI, payload: FastRegisterRequestI) => {
  // state.login = payload.login || '';
  // state.email = payload.email || '';
  // state.firstName = payload.firstName || '';
  // state.lastName = payload.lastName || '';
  let key: keyof FastRegisterRequestI = 'owner_name';
  for (key in payload) {
    state[key] = payload[key];
  }
  // state.id = payload.id;
  // state.name = payload.name;
  // state.description = payload.description;
  // state.legal_name = payload.legal_name;
  // state.itn = payload.itn;
  // state.psrn = payload.psrn;
  // state.address = payload.address;
  // state.legal_address = payload.legal_address;
  // state.email = payload.email;
  // state.phone = payload.phone;
  // state.link = payload.link;
  // state.activity = payload.activity;
  // state.owner_id = payload.owner_id;
  // state.rating = payload.rating;
  // state.docs = payload.docs;
};

export const AuthSlice = createSlice({
  name: 'authentification',
  initialState,
  reducers: {
    logout: (state: AuthStateI) => {
      Object.assign(state, initialState);
    },

    login: (state: AuthStateI, action: PayloadAction<FastRegisterRequestI>) => {
      state.isAuthorized = true;
      // state.token = action.payload.token;
      updateUser(state, action.payload);
    },

    // update: (state: AuthStateI, action: PayloadAction<ApiResponseUpdateI>) => {
    //   updateUser(state, action.payload);
    // },
  },
});

export const AuthActions = AuthSlice.actions;
export const selectAuth = (state: RootStateT) => state.AuthReducer;

export default AuthSlice.reducer;
