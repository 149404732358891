import saunaPic from '../../static/categoriesPics/1.png';
import staircasesPic from '../../static/categoriesPics/2.png';
import insulationPic from '../../static/categoriesPics/3.png';
import instrumentPic from '../../static/categoriesPics/4.png';
import roofPic from '../../static/categoriesPics/5.png';
import fastenersPic from '../../static/categoriesPics/6.png';
import finishingPic from '../../static/categoriesPics/7.png';
import lumberPic from '../../static/categoriesPics/8.png';
import plumberPic from '../../static/categoriesPics/9.png';
import buildingPic from '../../static/categoriesPics/10.png';
import houseHoldPic from '../../static/categoriesPics/11.png';
import companiesPic from '../../static/categoriesPics/companies_img.jpg';

const CategoriesData = [
  {name: 'Компании', translatedName: 'Companies', pic: companiesPic},
  {
    name: 'Баня и отопление',
    translatedName: 'sauna-and-heating',
    pic: saunaPic,
  },
  {
    name: 'Двери. Окна. Лестницы',
    translatedName: 'doors-windows-staircases',
    pic: staircasesPic,
  },
  {
    name: 'Изоляционные материалы',
    translatedName: 'insulation-materials',
    pic: insulationPic,
  },
  {name: 'Инструмент', translatedName: 'instrument', pic: instrumentPic},
  {
    name: 'Кровля. Фасад. Забор',
    translatedName: 'roof-facade-fence',
    pic: roofPic,
  },
  {
    name: 'Метиз и крепеж',
    translatedName: 'hardware-fasteners',
    pic: fastenersPic,
  },
  {
    name: 'Отделочные материалы',
    translatedName: 'finishing-materials',
    pic: finishingPic,
  },
  {name: 'Пиломатериалы', translatedName: 'lumber', pic: lumberPic},
  {name: 'Сантехника', translatedName: 'plumber', pic: plumberPic},
  {
    name: 'Строительные материалы',
    translatedName: 'building-materials',
    pic: buildingPic,
  },
  {
    name: 'Товары для дома и сада',
    translatedName: 'household',
    pic: houseHoldPic,
  },
];

export const MockedCategoriesSubCategories = new Map<string, number[]>([
  [
    'sauna-and-heating',
    [604, 605, 606, 607, 608, 401, 610, 611, 612, 613, 24, 615, 616],
  ],
  [
    'doors-windows-staircases',
    [
      618, 619, 620, 621, 622, 623, 624, 625, 626, 627, 628, 629, 630, 631, 632,
      633, 637, 638, 639, 640, 641, 222, 644, 329, 646, 646, 112, 649, 650,
    ],
  ],
  [
    'insulation-materials',
    [652, 653, 654, 655, 656, 657, 658, 659, 660, 661, 663],
  ],
  [
    'instrument',
    [
      665, 666, 667, 668, 669, 670, 671, 672, 673, 674, 675, 676, 677, 679, 680,
      682, 683, 684, 166, 686, 687, 688, 689, 690, 691, 692, 693, 694, 695, 696,
      697, 698, 699, 700, 484, 702, 703, 704, 705, 706, 708, 678, 710, 711, 712,
      713, 714, 715, 716, 717, 718, 719, 720, 721, 722, 723, 724, 725, 726, 727,
      500, 729, 730, 731, 732, 733, 582, 735,
    ],
  ],
  [
    'roof-facade-fence',
    [
      211, 739, 740, 741, 742, 743, 744, 745, 746, 747, 748, 749, 750, 751, 752,
      334, 754,
    ],
  ],
  ['hardware-fasteners', [757, 758, 759, 760, 761, 756, 763, 764, 765]],
  ['finishing-materials', [757, 758, 759, 760, 761, 756, 763, 764, 765]],
  ['lumber', [818, 819, 820, 822, 823, 824, 825, 826, 790, 828, 829, 830, 831]],
  [
    'plumber',
    [
      834, 243, 836, 837, 838, 839, 840, 841, 842, 843, 844, 845, 846, 833, 848,
      849, 850, 851, 852, 853, 854, 855, 856, 857, 858, 859, 860, 833, 862, 863,
      864, 865, 276, 867, 868, 276, 870, 871, 872, 729, 874, 876, 112, 874,
    ],
  ],
  [
    'building-materials',
    [
      880, 881, 882, 120, 884, 885, 886, 887, 888, 812, 890, 55, 892, 893, 69,
      895, 896, 897, 830, 900, 901, 902, 903, 903, 905, 906, 908, 909, 794, 911,
      912, 913, 914, 915, 798, 917, 586,
    ],
  ],
  [
    'household',
    [
      920, 921, 922, 923, 924, 926, 928, 929, 930, 931, 453, 670, 934, 935, 936,
      937, 938, 939, 940, 262, 942, 943, 713, 714, 946, 715, 948, 949, 950, 951,
      952, 953, 465, 955, 956, 957, 958, 959, 960, 141, 485, 964, 965, 966, 967,
      969, 970, 971, 930, 450, 974, 353, 977, 978, 979, 485, 582,
    ],
  ],
  [
    'electrical',
    [
      983, 984, 985, 986, 987, 988, 989, 990, 991, 992, 993, 994, 995, 997, 998,
      1000,
    ],
  ],
]);

export default CategoriesData;
MockedCategoriesSubCategories;
