import React, {FunctionComponent} from 'react';
import RootPage from './rootPage';
import FastOrderForm from './forms/fastOrder';


const FastOrderPage: FunctionComponent = () => {
  return (
    <RootPage nameOfActive='Order'>
      <div className='row theme-blue l-center white-text'>
        <h5 className='mv-20'>
          Покупайте товары и услуги для бизнеса
        </h5>
      </div>

      <div className='container section row'>
        <div className='col s12'>
          <h4>
            <span className='theme-blue-accent-text'>
              Подберем идеального контрагента лично&nbsp;
            </span>
            <span className='black-text'>
              для Вас
            </span>
          </h4>
          <p className='text-secondary'>
            Сервис подберет для Вас подходящие предложения,
            Вам необходимо только рассказать о необходимом товаре или сформировать коммерческое предложение
          </p>
        </div>
      </div>

      <FastOrderForm />
    </RootPage>
  );
};

export default FastOrderPage;
