/* eslint-disable */
import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import Card from '../components/card/card';
import RootPage from './rootPage';
import useDebounce from '../hooks/useDebounce';
import SearchIcon from '../images/search.svg';

import Ajax from '../api/ajax';

import './pages.scss';
import {Button, Chip, Stack, useMediaQuery} from '@mui/material';
import {useDispatch} from 'react-redux';
import {getUserMe} from '../redux/reducers/userReducer';
import {useAppSelector} from '../redux/hooks';
import {GoodT, changeCurrGoods} from '../redux/reducers/categoriesReducer';
import CategoriesData from '../components/CategoryCard/categories_data';
import store from '../redux/store';
import {useDebounce as useDebounceNew} from '../hooks/useDebounceObject';
import TextInput from '../components/NewInputs/TextInput';
import CatalogPageMobile from './CatalogPage/CatalogPageMobile';

const reauestProductsLimit = 12;
const reauestSearchProductsLimit = 12;

const CatalogPage: FC = () => {
  const [itemsLayout, setItemsLayout] = useState('table-layout');
  // const [activeSorting, setActiveSorting] = useState([0, 0, 0]);
  const [fetching, setFetching] = useState(true);
  const [searchReq, setSearchReq] = useState('');
  const [itemsAmount, setItemsAmount] = useState(0);
  const debouncedSearchReq = useDebounce(searchReq, 1000);
  const [tags, setTags] = useState<string[]>([]);
  const dispatch = useDispatch();
  const [isFiltersMode, setIsFiltersMode] = useState(false);
  const [fromPrice, setFromPrice] = useState('1');
  const [toPrice, setToPrice] = useState('10000');
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTotal, setTotal] = useState(false);
  const debouncedToPrice = useDebounceNew(toPrice, 1000);
  const debouncedFromPrice = useDebounceNew(fromPrice, 1000);
  let goods = useAppSelector((state) => state.categoryReducer.goods);
  let currCategory = useAppSelector((state) => state.categoryReducer.category);

  const isMobile = useMediaQuery('(max-width: 480px)');

  let currentValue;
  function handleCategoryChange() {
    let previousValue = currCategory;
    currentValue = store.getState().categoryReducer.category;

    if (previousValue !== currentValue) {
      goods = [];
      setFetching(true);
      setItemsAmount(0);
    }
  }

  const unsubscribe = store.subscribe(handleCategoryChange);
  let categoryFromLocalStorage = localStorage.getItem('CategoryName');
  const searchProcucts = () =>
    Ajax.post({
      url: `/search/products?skip=0&limit=${reauestSearchProductsLimit}`,
      body: JSON.stringify({name: debouncedSearchReq}),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (Array.isArray(res.response.data)) {
          const fetchedProducts = res.response.data as GoodT[];
          dispatch(changeCurrGoods(fetchedProducts));
        }
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
        setFetching(false);
      })
      .finally(() => setFetching(false));

  useEffect(() => {
    setIsLoading(true);
    Ajax.get({
      url: `/user/me`,
    }).then(({status, response}) => {
      if (status === Ajax.STATUS.ok) {
        setCurrentUser(response.data);
        dispatch(getUserMe(response?.data));
        setIsLoading(false);
        console.log('User new request');
      }
    });
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setFetching(true);
    }, 100);
    return () => clearTimeout(timeout);
  }, [debouncedFromPrice, debouncedToPrice]);
  useEffect(() => {
    if (fetching) {
      Ajax.post({
        url: `/products/list/filter?skip=${itemsAmount}&limit=${reauestProductsLimit}`,
        body: {
          product_name: '',
          category_name: CategoriesData.find(
            (val) => val.translatedName === categoryFromLocalStorage,
          )?.name,
          categories_ids: Array.from({length: 1001}, (_, index) => index + 1),
          price_lower_limit: Number(debouncedFromPrice),
          price_higher_limit: Number(debouncedToPrice),
        },
      })
        .then(({status, response}) => {
          if (status === Ajax.STATUS.ok) {
            setTotal(response.data == null);
            if (Array.isArray(response.data)) {
              const newProducts = response.data as GoodT[];
              store.dispatch(changeCurrGoods(newProducts));
              setFetching(false);
            }
          } else {
            console.warn(response.msg);
            setFetching(false);
          }
        })
        .catch((error) => {
          console.error('Error fetching products:', error);
          setFetching(false);
        })
        .finally(() => setFetching(false));
    }
  }, [fetching, debouncedFromPrice, debouncedToPrice]);

  const changeLayout = (
    layout: string,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setItemsLayout(layout);
  };

  const handleButtonClick = (e: any) => {
    e.preventDefault();

    setTags((prev) => [...prev, searchReq]);
    searchProcucts();
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      searchProcucts();
    }
  };
  const inputHandler = (e: React.FormEvent) => {
    const {value} = e.target as HTMLInputElement;
    setSearchReq(value);
  };

  const renderedProducts = Array.isArray(goods)
    ? goods.map(
        (
          {
            id,
            name,
            price,
            photo,
            description,
            category_id,
            category_name,
            company,
          },
          i,
        ) => {
          return (
            <Card
              key={'' + i}
              id={id}
              category_id={category_id}
              category_name={category_name}
              name={name}
              price={price}
              photo={photo ? photo[0] : ''}
              description={'Описание'}
              classList={'card ' + itemsLayout}
              currentUser={currentUser}
              legalName={company?.legal_name}
              companyId={company?.id}
            />
          );
        },
      )
    : 'Ничего не найдено';

  if (isMobile) {
    return (
      <CatalogPageMobile
        tags={tags}
        setTags={setTags}
        searchProcucts={searchProcucts}
        inputHandler={inputHandler}
        handleKeyDown={handleKeyDown}
        handleButtonClickSearch={handleButtonClick}
        goods={goods}
        currentUser={currentUser}
      />
    );
  }

  console.log('ISMOBILE', isMobile);

  return (
    <RootPage nameOfActive="CategoryPage">
      <div className="row theme-blue l-center white-text">
        <h5 className="mv-20">
          Товары и услуги для бизнеса так же просто, как для себя
        </h5>
      </div>
      <div className="catalog__header">
        <div className="catalog__container container !justify-end">
          <div className="catalog__search-wrapper">
            <img src={SearchIcon} alt="search" />
            <input
              onInput={(e) => inputHandler(e)}
              id="search-input"
              onKeyDown={handleKeyDown}
              type="text"
              className="catalog__search"
              placeholder="Введите название товара, артикул или продавци"
            />
          </div>
          <Button onClick={handleButtonClick}>Поиск</Button>
        </div>
      </div>
      <div className="container">
        <div className="row catalog__content">
          <aside className="catalog__sidebar">
            <div className="catalog__sidebar-filter">
              <h6 className="catalog__sidebar-title">Категории объявленя</h6>
              <select
                className="catalog__sidebar-select select"
                name="product-type"
              >
                <option value="написать вид товаров">
                  Написать вид товаров
                </option>
              </select>
            </div>
            <div className="catalog__sidebar-filter">
              <h6 className="catalog__sidebar-title">Производитель</h6>
              <ul className="catalog__sidebar-list">
                <li className="catalog__sidebar-item">
                  <input
                    type="checkbox"
                    id="manufacturer-1"
                    className="catalog__sidebar-checkbox"
                  />
                  <label
                    className="catalog__sidebar-label"
                    htmlFor="manufacturer-1"
                  >
                    1 АБВ
                  </label>
                </li>
                <li className="catalog__sidebar-item">
                  <input
                    type="checkbox"
                    id="manufacturer-2"
                    className="catalog__sidebar-checkbox"
                  />
                  <label
                    className="catalog__sidebar-label"
                    htmlFor="manufacturer-2"
                  >
                    2 АБВ
                  </label>
                </li>
                <li className="catalog__sidebar-item">
                  <input
                    type="checkbox"
                    id="manufacturer-3"
                    className="catalog__sidebar-checkbox"
                  />
                  <label
                    className="catalog__sidebar-label"
                    htmlFor="manufacturer-3"
                  >
                    3 АБВ
                  </label>
                </li>
                <li className="catalog__sidebar-item">
                  <input
                    type="checkbox"
                    id="manufacturer-4"
                    className="catalog__sidebar-checkbox"
                  />
                  <label
                    className="catalog__sidebar-label"
                    htmlFor="manufacturer-4"
                  >
                    4 АБВ
                  </label>
                </li>
              </ul>
              <a href="/" className="catalog__sidebar-link link">
                Показать еще
              </a>
            </div>
            <div className="catalog__sidebar-filter">
              <h6 className="catalog__sidebar-title">Поставщики</h6>
              <ul className="catalog__sidebar-list">
                <li className="catalog__sidebar-item">
                  <input
                    type="checkbox"
                    id="provider-1"
                    className="catalog__sidebar-checkbox"
                  />
                  <label
                    className="catalog__sidebar-label"
                    htmlFor="provider-1"
                  >
                    АБВ
                  </label>
                </li>
                <li className="catalog__sidebar-item">
                  <input
                    type="checkbox"
                    id="provider-2"
                    className="catalog__sidebar-checkbox"
                  />
                  <label
                    className="catalog__sidebar-label"
                    htmlFor="provider-2"
                  >
                    АБВ
                  </label>
                </li>
                <li className="catalog__sidebar-item">
                  <input
                    type="checkbox"
                    id="provider-3"
                    className="catalog__sidebar-checkbox"
                  />
                  <label
                    className="catalog__sidebar-label"
                    htmlFor="provider-3"
                  >
                    АБВ
                  </label>
                </li>
                <li className="catalog__sidebar-item">
                  <input
                    type="checkbox"
                    id="provider-4"
                    className="catalog__sidebar-checkbox"
                  />
                  <label
                    className="catalog__sidebar-label"
                    htmlFor="provider-4"
                  >
                    АБВ
                  </label>
                </li>
              </ul>
              <a href="/" className="catalog__sidebar-link link">
                Показать еще
              </a>
            </div>
            <div className="catalog__sidebar-filter">
              <h6 className="catalog__sidebar-title">Цена</h6>
              <div className="flex gap-4">
                <PriceInput type={'from'} state={[fromPrice, setFromPrice]} />
                <PriceInput type={'to'} state={[toPrice, setToPrice]} />
              </div>
              <div className="catalog__sidebar-item">
                <input
                  type="checkbox"
                  id="discount"
                  className="catalog__sidebar-checkbox"
                />
                <label className="catalog__sidebar-label" htmlFor="discount">
                  Скидка
                </label>
              </div>
            </div>
            <div className="catalog__sidebar-filter">
              <h6 className="catalog__sidebar-title">Наличие</h6>
              <ul className="catalog__sidebar-list">
                <li className="catalog__sidebar-item">
                  <input
                    type="checkbox"
                    id="availability-1"
                    className="catalog__sidebar-checkbox"
                  />
                  <label
                    className="catalog__sidebar-label"
                    htmlFor="availability-1"
                  >
                    В наличии
                  </label>
                </li>
                <li className="catalog__sidebar-item">
                  <input
                    type="checkbox"
                    id="availability-2"
                    className="catalog__sidebar-checkbox"
                  />
                  <label
                    className="catalog__sidebar-label"
                    htmlFor="availability-2"
                  >
                    Под заказ
                  </label>
                </li>
              </ul>
              <a href="/" className="catalog__sidebar-link link">
                Показать еще
              </a>
            </div>
            <div className="catalog__sidebar-filter">
              <h6 className="catalog__sidebar-title">Доставка</h6>
              <ul className="catalog__sidebar-list">
                <li className="catalog__sidebar-item">
                  <input
                    type="checkbox"
                    id="delivery-1"
                    className="catalog__sidebar-checkbox"
                  />
                  <label
                    className="catalog__sidebar-label"
                    htmlFor="delivery-1"
                  >
                    Самовывоз
                  </label>
                </li>
                <li className="catalog__sidebar-item">
                  <input
                    type="checkbox"
                    id="delivery-2"
                    className="catalog__sidebar-checkbox"
                  />
                  <label
                    className="catalog__sidebar-label"
                    htmlFor="delivery-2"
                  >
                    Доставка от компании
                  </label>
                </li>
                <li className="catalog__sidebar-item">
                  <input
                    type="checkbox"
                    id="delivery-3"
                    className="catalog__sidebar-checkbox"
                  />
                  <label
                    className="catalog__sidebar-label"
                    htmlFor="delivery-3"
                  >
                    Почта или курьер
                  </label>
                </li>
                <li className="catalog__sidebar-item">
                  <input
                    type="checkbox"
                    id="delivery-4"
                    className="catalog__sidebar-checkbox"
                  />
                  <label
                    className="catalog__sidebar-label"
                    htmlFor="delivery-4"
                  >
                    Транспортная компания
                  </label>
                </li>
              </ul>
              <div className="catalog__sidebar-inputs">
                <span className="from">от</span>
                <input type="text" className="catalog__sidebar-input" />
                <span className="to">до</span>
                <input type="text" className="catalog__sidebar-input" />
              </div>
            </div>
          </aside>
          <div className="catalog__main  mt-10">
            {tags.map((item, index) => {
              return (
                <Chip
                  label={item}
                  key={index}
                  variant="outlined"
                  onDelete={() => {
                    const updatedTags = tags.filter((tag) => tag !== item);
                    setTags(updatedTags);
                    searchProcucts();
                  }}
                />
              );
            })}

            <div className="flex justify-end">
              <div className="items-layout">
                <a
                  onClick={(e) => changeLayout('list-layout', e)}
                  href="/catalog"
                >
                  <i className="material-icons">view_list</i>
                </a>
                <a
                  onClick={(e) => changeLayout('table-layout', e)}
                  href="/catalog"
                >
                  <i className="material-icons">view_module</i>
                </a>
              </div>
            </div>
            {/* <div
              className="px-2 py-3 border-2 rounded-md font-semibold cursor-pointer w-fit"
              onClick={() =>
                dispatch(
                  // @ts-ignore
                  changeSubCat(MockedCategoriesArr[categoryFromLocalStorage]),
                )
              }
            >
              Отменить фильтры
            </div> */}

            <section className={'d-flex catalog ' + itemsLayout}>
              {renderedProducts}
            </section>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Button
                sx={{
                  backgroundColor: '#375BA1',
                  color: 'white',
                  float: 'right',
                  '&:disabled': {
                    backgroundColor: 'grey',
                  },
                  '&:hover': {
                    backgroundColor: '#375BA1',
                  },
                  '&:focus': {
                    backgroundColor: '#375BA1',
                  },
                }}
                disabled={itemsAmount < 12 ? true : false}
                onClick={() => {
                  setItemsAmount((prev) => prev - reauestProductsLimit);
                  setFetching(true);
                }}
              >
                Назад{' '}
              </Button>

              <Button
                disabled={isTotal}
                sx={{
                  backgroundColor: '#375BA1',
                  color: 'white',
                  float: 'right',
                  '&:disabled': {
                    backgroundColor: 'grey',
                  },
                  '&:hover': {
                    backgroundColor: '#375BA1',
                  },
                  '&:focus': {
                    backgroundColor: '#375BA1',
                  },
                }}
                onClick={() => {
                  setItemsAmount((prev) => prev + reauestProductsLimit);
                  setFetching(true);
                }}
              >
                Далее
              </Button>
            </Stack>
          </div>
        </div>
      </div>
    </RootPage>
  );
};

const PriceInput: FC<{
  type: 'from' | 'to';
  state: [string, Dispatch<SetStateAction<string>>];
}> = ({type, state}) => {
  return (
    <div
      className="flex bg-[#FFFFFF] gap-1 p-[15px]   rounded-[6px] items-center"
      style={{
        border: '1px solid rgba(29, 54, 83, 0.17)',
      }}
    >
      <span className="text-[#707895]">{type === 'from' ? 'от' : 'до'}</span>

      <input
        type="text"
        value={state[0]}
        onChange={(e) => {
          state[1](e.target.value);
        }}
        style={{
          borderBottom: '0px',
          boxShadow: 'none',
          height: 'fit-content',
          marginBottom: '0px',
        }}
      />
    </div>
  );
};
export default CatalogPage;
