import React from 'react';
import Header from '../../components/header/headerComponent'; // общий хэдер для проекта
import './landing.scss';
import Main from './Main';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import AuthField from '../../components/AuthField';
import Footer from '../../components/Footer';
import {useMediaQuery} from '@mui/material';
import FooterMobile from '../../components/FooterMobile';

const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
});

const Landing: React.FC = () => {
  const matches = useMediaQuery('(max-width: 700px');
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Main />
      <AuthField />
      {matches ? <FooterMobile /> : <Footer />}
    </ThemeProvider>
  );
};

export default Landing;
