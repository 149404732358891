import {Stack, Typography} from '@mui/material';
import React from 'react';
import iconCard from '../../../static/aboutPage/iconCard.png';
import styles from './cardsBlock.module.scss';
import {mockData} from './mockData';

export default function CardsBlock() {
  return (
    <Stack
      component={'section'}
      sx={{
        maxWidth: '1314px',
        width: '100%',
        heigh: '592px',
        margin: '80px 64px 72px',
        borderRadius: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
    >
      {mockData.map((item) => {
        return (
          <Stack
            key={item.id}
            display="flex"
            flexDirection={'column'}
            justifyContent={'space-between'}
            alignContent={'flex-start'}
          >
            <img src={item.icon} alt="иконка" className={styles.iconCard} />
            <Typography
              sx={{fontSize: '40px', fontWeight: '600', color: '#5075F9'}}
            >
              {item.title}
            </Typography>
            <Typography
              sx={{fontSize: '20px', fontWeight: '500', color: '#272121'}}
            >
              {item.subtitle}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
}
