import React, {FC, FormEvent, FormEventHandler, useState} from 'react';
import {Link} from 'react-router-dom';
import ValidatableInput from '../../components/validatableInput/validatableInputComponent';
import regexpValidation from '../../utils/regexp';

interface LoginFormPropsI {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  manageEmailState: [string, (s: string) => void];
  managePasswordState: [string, (s: string) => void];
  srvErr: string;
  isRememberMeChecked: boolean;
  setIsRememberMeChecked: (newIsRememberMeChecked: boolean) => void;
}

const LoginFormComponent: FC<LoginFormPropsI> = (props) => {
  const {
    manageEmailState,
    managePasswordState,
    srvErr,
    isRememberMeChecked,
    setIsRememberMeChecked,
  } = props;

  const [stageOnePreviousError, setStageOnePreviousError] = useState('');
  const [showStageOneError, setShowStageOneError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const stageOneValid =
    isEmailValid &&
    isPasswordValid &&
    managePasswordState[0] &&
    managePasswordState[0];

  let stageOneInvalidReason = 'Нарушен формат полей';
  if (!stageOneValid) {
    if (manageEmailState[0] === '') {
      stageOneInvalidReason = 'Пропущено поле E-mail';
    } else if (managePasswordState[0] === '') {
      stageOneInvalidReason = 'Пропущено поле Пароль';
    }
  }

  // предыдущая ошибка исправлена
  if (showStageOneError && stageOneInvalidReason !== stageOnePreviousError) {
    setShowStageOneError(false);
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (stageOneValid) {
      props.handleSubmit(e);
      return;
    }
    setShowStageOneError(true);
    setStageOnePreviousError(stageOneInvalidReason);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4>Войти</h4>
      <div className="row"></div>
      <div className="row">
        <ValidatableInput
          className="col s12"
          id="E-mail"
          labelSign="E-mail"
          type="email"
          required={true}
          trimmed={true}
          tooltipWidth="200px"
          conditions={regexpValidation.email.conditions}
          validState={[isEmailValid, setIsEmailValid]}
          valueState={manageEmailState}
        />
      </div>
      <div className="row">
        <ValidatableInput
          className="col s12"
          id="password"
          type="password"
          labelSign="Пароль"
          trimmed={false}
          required={true}
          tooltipWidth="200px"
          conditions={regexpValidation.password.conditions}
          validState={[isPasswordValid, setIsPasswordValid]}
          valueState={managePasswordState}
        />
      </div>

      <div className="row">
        <p>{showStageOneError ? stageOneInvalidReason : ''}</p>
      </div>

      {/* TODO: ошибки сервера */}
      {srvErr ? (
        <div className="row">
          <p>{srvErr}</p>
        </div>
      ) : (
        ''
      )}

      <div className="row">
        <label
          className="col"
          style={{display: 'flex', alignItems: 'center', gap: '4px'}}
        >
          <input
            type="checkbox"
            className="filled-in theme"
            defaultChecked={isRememberMeChecked}
            onChange={(prev) => setIsRememberMeChecked(!prev)}
          />
          <h6>Запомнить меня</h6>
        </label>
        <div className="col valign-wrapper right" style={{height: '25px'}}>
          <Link to="#">Забыли пароль?</Link>
        </div>
      </div>

      <div className="row"></div>

      <div className="row">
        <div className="col">
          <button
            className="btn theme-blue waves-effect waves-light"
            type="submit"
          >
            Войти
            <i className="material-icons right">arrow_forward</i>
          </button>
        </div>
        <div className="col right" style={{padding: '0'}}>
          <Link to={'/register'}>
            <button
              className="btn-flat theme-border theme-white waves-effect waves-light"
              type="button"
            >
              Регистрация
            </button>
          </Link>
        </div>
      </div>
    </form>
  );
};

export default LoginFormComponent;
