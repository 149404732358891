/*eslint-disable */
import React, {FormEventHandler, useState} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SecondStep from '../../../../components/Modals/SecondStep';
import Ajax from '../../../../api/ajax';
import ValidatableInput from '../../../../components/validatableInput/validatableInputComponent';
import regexpValidation from '../../../../utils/regexp';
import {SearchCategoriesResponseI} from '../../../../api/messageTypes';
import {SuggestionsInputStateI} from '../../../../components/suggestionsInput/suggestionsInputComponent';
import SuggestionsInput from '../../../../components/suggestionsInput/suggestionsInputComponent';
import {ajaxDebug} from '../../../../utils/globals';
import {fastOrderValidation} from '../../../forms/fastOrderValidation';
import CompanyCategoryRenderOption from '../../../../components/suggestionsInput/CompanyCategoryRenderOption';
import s from './forms.module.scss';
import {useMediaQuery} from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FastOrderFormPropsI {
  isMobile: boolean;
}

const Forms: React.FC<FastOrderFormPropsI> = ({isMobile}) => {
  const [productCategory, setProductCategory] = useState('');
  const [orderText, setOrderText] = useState('');
  const [adress, setAdress] = useState('');
  const [date, setDate] = useState('');
  const [productCategorySuggestions, setProductCategorySuggestions] = useState<
    SuggestionsInputStateI<SearchCategoriesResponseI>
  >({
    records: [],
    currentRecordIndex: -1,
  });

  const [isProductCategoryValid, setIsProductCategoryValid] = useState(false);
  const [isAdressValid, setIsAdressValid] = useState(false);
  const [isDateValid, setIsDateValid] = useState(false);
  const [isOrderTextValid, setIsOrderTextValid] = useState(false);

  const [showError, setShowError] = useState(false);
  const [previousErrorMsg, setPreviousErrorMsg] = useState('');
  const [open, setOpen] = React.useState(false);
  const [requestBody, setRequestBody] = useState({});

  const matches = useMediaQuery('(max-width: 768px)');

  const isValid =
    isProductCategoryValid && !!productCategory && isAdressValid && !!adress;

  let errorMsg = 'Нарушен формат полей';
  if (!productCategory) {
    errorMsg = 'Пропущено поле Категория товара';
  } else if (!orderText) {
    errorMsg = 'Пропущено поле Информация о товаре';
  }
  if (showError && errorMsg !== previousErrorMsg) {
    setShowError(false);
  }

  const submitHandler: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!isValid) {
      return;
    }

    if (
      productCategory &&
      orderText &&
      adress &&
      date &&
      productCategorySuggestions
    ) {
      const requestBody = {
        product_category: productCategory,
        delivery_address: adress,
        delivery_date: date,
        order_text: orderText,
      };
      setRequestBody(requestBody);
      setOpen(true);
    }
  };

  const handleOpen = () => {
    if (
      isProductCategoryValid &&
      isAdressValid &&
      isDateValid &&
      isOrderTextValid
    ) {
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);
  const handleIconClose = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={
        isMobile
          ? {marginX: '20px', paddingBottom: '25px', maxHeight: '100vh'}
          : {margin: '24px 63px'}
      }
      component="section"
      className={s.forms_field}
    >
      <Typography sx={{fontSize: '28px', fontWeight: 'bold'}}>
        Форма быстрого заказа
      </Typography>
      <Box component="form" onSubmit={submitHandler}>
        <Box className={s.form_block}>
          <SuggestionsInput<SearchCategoriesResponseI>
            // eslint-disable-next-line
            createRequestPromise={async (
              query: string,
            ): Promise<SearchCategoriesResponseI[]> => {
              if (!query) {
                if (ajaxDebug)
                  // eslint-disable-next-line
                  console.log(
                    '[FastOrder Form => SuggestionsInput] не отправляю пустое поле',
                  );
                return (async () => [])();
              }

              return Ajax.post(
                {
                  url: `/search/categories?skip=0&limit=6`,
                  body: {name: query},
                },
                true,
              )
                .then(({status, response}) => {
                  return (
                    (status === Ajax.STATUS.ok &&
                      (response.data as SearchCategoriesResponseI[])) ||
                    []
                  );
                })
                .catch((err) => {
                  console.warn(err);
                  return [];
                });
            }}
            substituteOnClick={(suggestion: {name: string}) => suggestion.name}
            validatableInputProps={{
              conditions: fastOrderValidation.productCategory.conditions,
              required: true,
              trimmed: false,
              labelSign: 'Категория',
              id: 'item-category',
              tooltipWidth: '200px',
              validState: [isProductCategoryValid, setIsProductCategoryValid],
            }}
            manageSuggestionsState={[
              productCategorySuggestions,
              setProductCategorySuggestions,
            ]}
            manageValueState={[productCategory, setProductCategory]}
            RenderOption={CompanyCategoryRenderOption}
          />
        </Box>
        <Box className={s.form_block}>
          <ValidatableInput
            id="adress"
            type="text"
            labelSign="Адрес доставки"
            trimmed={false}
            required={true}
            conditions={regexpValidation.template.conditions}
            valueState={[adress, setAdress]}
            validState={[isAdressValid, setIsAdressValid]}
          />
        </Box>
        <Box className={s.form_block}>
          <ValidatableInput
            id="actor-email"
            type="text"
            labelSign="Дата доставки"
            trimmed={true}
            required={true}
            conditions={regexpValidation.date.conditions}
            valueState={[date, setDate]}
            validState={[isDateValid, setIsDateValid]}
          />
        </Box>
        <Box className={s.form_block}>
          <ValidatableInput
            id="actor-email"
            type="text"
            labelSign="Описание заявки"
            trimmed={false}
            required={true}
            conditions={regexpValidation.template.conditions}
            valueState={[orderText, setOrderText]}
            validState={[isOrderTextValid, setIsOrderTextValid]}
          />
        </Box>
        {!isValid ? (
          <Box className={s.errorMessage}>{showError ? errorMsg : ''}</Box>
        ) : (
          ''
        )}
        <Button
          variant="contained"
          type="submit"
          fullWidth
          sx={{
            color: 'white',
            float: 'right',
            width: '50%',
            position: 'relative',
            marginTop: '1%',
          }}
          style={{backgroundColor: '#375ba1'}}
          onClick={handleOpen}
          className="btn-primary theme-blue"
        >
          Отправить
        </Button>
      </Box>
      <SecondStep
        open={open}
        handleClose={handleClose}
        handleIconClose={handleIconClose}
        data={requestBody}
        isMobile={matches ? true : false}
      />
    </Box>
  );
};

export default Forms;
