import {Stack, Box, Typography} from '@mui/material';
import React from 'react';
import {mockDataCard} from './mockData';

export default function CardsAdvantages() {
  return (
    <Stack
      display="flex"
      flexDirection={'row'}
      gap={'32px'}
      justifyContent={'space-between'}
      sx={{
        margin: '64px 0 0',
      }}
    >
      {mockDataCard.map((item) => {
        return (
          <Box
            key={item.id}
            sx={{
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              width: '324px',
              height: '296px',
              padding: '32px 24px 48px',
              borderRadius: '16px',
              background:
                // eslint-disable-next-line max-len
                'radial-gradient(119.84% 138.71% at 4.44% 3.67%, rgba(229, 238, 255, 0.18) 0%, rgba(229, 238, 255, 0.00) 100%), linear-gradient(202deg, #6C8BF6 2.84%, #2E55DE 122.89%)',
            }}
          >
            <Typography
              fontSize={'40px'}
              fontWeight={'600'}
              lineHeight={'48px'}
              color={'#FFF'}
            >
              {item.title}
            </Typography>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                justifyContent: 'space-between',
              }}
            >
              {item.subtitle.map((i) => {
                return (
                  <Typography
                    key={i.id}
                    fontSize={'20px'}
                    fontWeight={'500'}
                    lineHeight={'24px'}
                    color={'#FFF'}
                  >
                    {i.text}
                  </Typography>
                );
              })}
            </Stack>
          </Box>
        );
      })}
    </Stack>
  );
}
